import { Box, Button, Theme, Typography } from '@mui/material';
import React, { ChangeEvent, useContext, useState } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import Navbar from '../../../components/NavBar';
import { Link, useParams } from 'react-router-dom';
import { Colors } from '../../../pegasus/Colors';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddressBookTable from './AddressBookTable';
import { Search } from '../../../components/form/Search';
import { Formik } from 'formik';
import UserContext from '../../../context/user';
import { PIcon } from '../../../pegasus/PIcon';
import useAddressBookApiRoutes from '../../../hooks/api/useAddressBookApiRoutes';
import useGetUserRoutePermissions from '../../../hooks/useGetUserRoutePermissions';
const styles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: Colors.primary.background,
      flex: 1,
    },
    tabContent: {
      paddingTop: theme.spacing(4),
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
    },
    darkButton: {
      backgroundColor: Colors.primary.darkMode,
      marginRight: theme.spacing(1),
    },
    select: {
      width: 200,
    },
  })
);

const AddressBook = () => {
  const classes = styles();
  const { countryCode = '' } = useParams<'countryCode'>();
  const { user } = useContext(UserContext);
  const { canSeeOperationsDashboard } = useGetUserRoutePermissions();
  const { exportCsv } = useAddressBookApiRoutes();
  const [addressBookFilter, setAddressBookFilter] = useState<{
    searchText: string;
  }>({ searchText: '' });

  const exportCsvFile = () => {
    if (countryCode) {
      exportCsv({ countryCode: countryCode }).then((response) => {
        const csvFileBlob = new Blob([response], { type: 'text/csv' });
        const blobUrl = URL.createObjectURL(csvFileBlob);
        window.open(blobUrl, '_top');
      });
    }
  };

  return (
    <Box className={classes.container}>
      <Navbar>
        <Box display="flex" alignItems="center">
          <Box flex={1}>
            <Typography ml={1} flex={1} variant="displayMedium" color="primary">
              Address Book
            </Typography>
          </Box>
          <Box flex={3} textAlign="end">
            {user?.type === 'admin' ? (
              <Button
                sx={{ marginTop: 0.5, marginBottom: 0.5, marginRight: 1 }}
                onClick={exportCsvFile}
                variant="outlined"
                size="medium"
              >
                <PIcon sx={{ marginRight: 1 }} name="file" />
                Export CSV
              </Button>
            ) : null}
            <Formik
              validationDisabled={true}
              initialValues={addressBookFilter}
              onSubmit={setAddressBookFilter}
              enableReinitialize
            >
              {({ handleSubmit, handleChange }) => {
                const handleSearchChange = (
                  event: React.FormEvent<HTMLFormElement> | ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => {
                  handleChange(event);
                  handleSubmit();
                };
                return (
                  <>
                    <Search
                      name="searchText"
                      placeholder="Search by Company, Address or Contact"
                      onChange={handleSearchChange}
                      sx={{ width: '400px', marginTop: 0.5, marginBottom: 0.5, marginRight: 1 }}
                    />
                  </>
                );
              }}
            </Formik>
            {canSeeOperationsDashboard && (
              <Button variant="contained" size="medium" to={`/operations/${countryCode}/dashboard`} component={Link}>
                <ArrowBackIcon sx={{ marginRight: 1 }} />
                Back to Consignments
              </Button>
            )}
          </Box>
        </Box>
      </Navbar>
      <Box className={classes.tabContent}>
        <AddressBookTable filter={addressBookFilter} />
      </Box>
    </Box>
  );
};

export default AddressBook;
