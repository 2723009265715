import { Box, Button } from '@mui/material';
import React, { useCallback, useContext, useMemo } from 'react';
import ModalContext from '../../../context/modal';
import { FormikHelpers } from 'formik';
import SnackBarContext from '../../../context/snackbar';
import ConsignmentForm, { ConsignmentFormValues } from './ConsignmentForm';
import { SubmitButton } from '../../../pegasus/PSubmit';
import useConsignmentApiRoutes from '../../../hooks/api/useConsignmentApiRoutes';
import { Consignment } from '../../../../types';
import pick from 'lodash/pick';

const NewConsignmentModal = ({ countryCode }: { countryCode: string }) => {
  const { closeDrawer, isDrawerOpen, consignment } = useContext(ModalContext);
  const { showSnack } = useContext(SnackBarContext);
  const { create } = useConsignmentApiRoutes();
  const isDuplicate = isDrawerOpen('duplicate-consignment');
  const initialValues = useMemo<Partial<Consignment>>(() => {
    const baseFields: Partial<Consignment> = {
      status: 'unassigned',
      specialInstructions: [
        {
          text: '',
          createdAt: new Date().getTime(),
        },
      ],
    };
    const otherFields: Partial<Consignment> = isDuplicate
      ? pick(consignment, [
          'countryCode',
          'orderType',
          'depot',
          'sender',
          'receiver',
          'signatureRequired',
          'photoPodRequired',
          'urgent',
          'tailLiftRequired',
          'customsMpiFoodSafety',
          'fragile',
          'saturdayDelivery',
          'temperatureControlRequired',
          'goodsType',
          'calculateVolumeBy',
          'deliveryInstructions',
          'lineItems',
        ])
      : {
          countryCode: countryCode,
          sender: {
            countryCode: countryCode,
            companyName: '',
            address: '',
            additionalInfo: '',
            contactName: '',
            phone: '',
            email: '',
            areaZone: '',
            milestones: true,
            labels: true,
            contactEmails: [],
            originCountryCode: '',
            state: '',
          },
          receiver: {
            countryCode: countryCode,
            companyName: '',
            address: '',
            additionalInfo: '',
            contactName: '',
            phone: '',
            email: '',
            contactEmails: [],
            originCountryCode: '',
            state: '',
          },
          signatureRequired: false,
          photoPodRequired: false,
          urgent: false,
          tailLiftRequired: false,
          customsMpiFoodSafety: false,
          fragile: false,
          saturdayDelivery: false,
          temperatureControlRequired: false,
          goodsType: 'general',
          calculateVolumeBy: 'unitType',
        };
    return { ...baseFields, ...otherFields };
  }, [consignment, countryCode, isDuplicate]);

  const closeModal = useCallback(() => {
    closeDrawer(isDuplicate ? 'duplicate-consignment' : 'new-consignment');
  }, [closeDrawer, isDuplicate]);

  const handleSubmit = (values: ConsignmentFormValues, { setSubmitting }: FormikHelpers<ConsignmentFormValues>) => {
    create(values)
      .then(() => {
        setSubmitting(false);
        showSnack('Consignment Created!', 'success');
        closeModal();
      })
      .catch(() => setSubmitting(false));
  };

  return (
    <Box color="primary" display="flex" flexDirection="column" flex={1}>
      <ConsignmentForm onSubmit={handleSubmit} initialValues={initialValues}>
        <SubmitButton color="primary" sx={{ marginRight: 1 }}>
          Submit New Consignment
        </SubmitButton>
        <Button variant="outlined" onClick={closeModal}>
          Cancel
        </Button>
      </ConsignmentForm>
    </Box>
  );
};

export default NewConsignmentModal;
