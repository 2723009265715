import { DrawerType } from '../../types';
export type ModalType = 'large' | 'small';
const drawerTypes: {
  name: DrawerType;
  modalType: ModalType;
}[] = [
  { name: 'new-consignment', modalType: 'large' },
  { name: 'duplicate-consignment', modalType: 'large' },
  { name: 'view-consignment', modalType: 'large' },
  { name: 'edit-consignment', modalType: 'large' },
  { name: 'edit-check-in-sheet', modalType: 'large' },
  { name: 'assign-driver', modalType: 'small' },
  { name: 'new-user', modalType: 'small' },
  { name: 'new-driver', modalType: 'small' },
  { name: 'new-depot', modalType: 'small' },
  { name: 'edit-user', modalType: 'small' },
  { name: 'edit-profile', modalType: 'small' },
  { name: 'edit-address-book', modalType: 'small' },
];

export default drawerTypes;

export const largeModalTypes = drawerTypes.filter((d) => d.modalType === 'large').map((d) => d.name);

export const getModalType = (drawerType: DrawerType): ModalType => {
  const entry = drawerTypes.find((d) => d.name === drawerType);
  return entry ? entry.modalType : 'large';
};
