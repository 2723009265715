import AustraliaFlag from '../imgs/australia_flag.png';
import FujiFlag from '../imgs/fiji_flag.png';
import NewZealandFlag from '../imgs/new_zealand_flag.png';

export type Country = {
  name: string;
  code: string;
  image: string;
};
const countries: Country[] = [
  {
    name: 'Australia',
    code: 'AU',
    image: AustraliaFlag,
  },
  {
    name: 'Fiji',
    code: 'FJ',
    image: FujiFlag,
  },
  {
    name: 'New Zealand',
    code: 'NZ',
    image: NewZealandFlag,
  },
];
export default countries;
