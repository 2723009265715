import { Box, Theme } from '@mui/material';
import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';

const indicatorSize = 10;
const styles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      overflow: 'hidden',
    },
    label: {
      flex: 1,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    indicator: {
      width: indicatorSize,
      height: indicatorSize,
      borderRadius: indicatorSize,
      backgroundColor: theme.palette.error.dark,
    },
  })
);

export default function TabLabel({ label, showIndicator }: { label: string; showIndicator: boolean }) {
  const classes = styles();
  return (
    <Box className={classes.container}>
      <Box className={classes.label}>{label}</Box>
      {showIndicator ? <Box className={classes.indicator}></Box> : null}
    </Box>
  );
}
