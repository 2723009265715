import React, { useEffect } from 'react';
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Typography,
  useTheme,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { PTextField, PTextFieldReadOnly, ThemedTextFieldProps } from '../../../pegasus/PTextField';
import { PSelect } from '../../../pegasus/PSelect';
import { ConsignmentFormValues } from './ConsignmentForm';
import { PCheckbox } from '../../../pegasus/PCheckbox';
import { Consignment, LineItem, lineItemTypes } from '../../../../types';
import { get } from 'lodash';
import useGetTotalCube, { useGetRowTotals } from '../../../hooks/useGetTotalCube';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import { Colors } from '../../../pegasus/Colors';
import InfoIcon from '@mui/icons-material/Info';
import ClearIcon from '@mui/icons-material/Clear';
import { ErrorLabel } from '../../../pegasus/ErrorLabel';

const OrderDetailsSection = () => {
  const { values, setFieldValue, errors } = useFormikContext<ConsignmentFormValues>();

  const addRow = () => {
    setFieldValue('lineItems', [
      ...(values.lineItems || []),
      {
        type: 'select',
        units: 0,
        length: 0,
        width: 0,
        height: 0,
        weight: 0,
      },
    ]);
  };

  return (
    <>
      <Typography variant="displayXSmallBold" color="inherit" mb={3}>
        Order Details
      </Typography>

      <VolumeCalculation />
      {values.lineItems?.map((lineItem, index) => (
        <Grid container key={index} spacing={0.5} columns={13.5}>
          <LineItemRow index={index} lineItemsFieldName="lineItems" />
        </Grid>
      ))}
      <TotalsRow />

      {errors.lineItems && (
        <Box mb={2}>
          <ErrorLabel errorText={errors.lineItems} />
        </Box>
      )}
      <Button
        variant="outlined"
        color={errors.lineItems ? 'error' : 'inherit'}
        fullWidth
        sx={{ marginBottom: 2.5 }}
        onClick={addRow}
      >
        Add Another Row
      </Button>

      <BooleanOptions />
    </>
  );
};

const VolumeCalculation = () => {
  const { values, handleChange } = useFormikContext<ConsignmentFormValues>();
  const theme = useTheme();

  return (
    <>
      <RadioGroup sx={{ mb: 2 }} value={values.calculateVolumeBy} name="calculateVolumeBy" onChange={handleChange} row>
        <FormControlLabel value="unitType" control={<Radio />} label="Calculate by Unit Type" />
        <FormControlLabel value="totalShipment" control={<Radio />} label="Calculate by Total Shipment" />
      </RadioGroup>
      {values.calculateVolumeBy === 'totalShipment' && (
        <Paper sx={{ mb: 2, overflow: 'hidden' }}>
          <Box sx={{ p: 3, borderLeft: `4px solid ${theme.palette.info.main}` }}>
            <Grid container spacing={3}>
              <Grid item>
                <InfoIcon color="info" />
              </Grid>
              <Grid item xs>
                <Typography>
                  Calculating by total shipment is less accurate and therefore more likely to incur an additional charge
                  for inaccurate or oversized dimensions.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      )}
    </>
  );
};

export const LineItemRow = ({
  index,
  lineItemsFieldName,
  showLabels = true,
}: {
  index: number;
  lineItemsFieldName: string;
  showLabels?: boolean;
}) => {
  const { values, setFieldValue } = useFormikContext<ConsignmentFormValues>();
  const lineItem = get(values, `${lineItemsFieldName}.${index}`);
  const size = showLabels ? 'medium' : 'small';

  const commonNumberProps: Partial<ThemedTextFieldProps> = {
    size: size,
    fullWidth: true,
    type: 'number',
    enableClear: false,
  };

  const getTotalCube = useGetTotalCube();
  const cube = getTotalCube(lineItem, values.calculateVolumeBy);

  const handleDelete = () => {
    const filteredLineItems = (get(values, lineItemsFieldName) as Array<LineItem>).filter((v, i) => i !== index);
    setFieldValue(lineItemsFieldName, filteredLineItems);
  };

  return (
    <>
      <Grid item xs={2} position="relative">
        <IconButton color="error" onClick={handleDelete} sx={{ position: 'absolute', top: 16, left: -40 }}>
          <ClearIcon />
        </IconButton>
        <PSelect
          name={`${lineItemsFieldName}.${index}.type`}
          fullWidth
          label={showLabels ? 'Type' : undefined}
          size={size}
        >
          <MenuItem disabled={true} value="select">
            Select
          </MenuItem>
          {lineItemTypes.map((lineItemType) => (
            <MenuItem key={lineItemType.code} value={lineItemType.code}>
              {lineItemType.name}
            </MenuItem>
          ))}
        </PSelect>
      </Grid>
      <Grid item xs={1.5}>
        <PTextField
          name={`${lineItemsFieldName}.${index}.units`}
          label={showLabels ? 'Units' : undefined}
          {...commonNumberProps}
        />
      </Grid>
      <Grid item xs={2}>
        <PTextField
          name={`${lineItemsFieldName}.${index}.weight`}
          label={showLabels ? 'Weight(kg)' : undefined}
          {...commonNumberProps}
        />
      </Grid>
      <Grid item xs={2}>
        <PTextField
          name={`${lineItemsFieldName}.${index}.length`}
          label={showLabels ? 'Length(cm)' : undefined}
          {...commonNumberProps}
        />
      </Grid>
      <Grid item xs={2}>
        <PTextField
          name={`${lineItemsFieldName}.${index}.width`}
          label={showLabels ? 'Width(cm)' : undefined}
          {...commonNumberProps}
        />
      </Grid>
      <Grid item xs={2}>
        <PTextField
          name={`${lineItemsFieldName}.${index}.height`}
          label={showLabels ? 'Height(cm)' : undefined}
          {...commonNumberProps}
        />
      </Grid>
      {values.calculateVolumeBy === 'totalShipment' ? (
        <Grid item xs={2}>
          <PTextField
            name={`${lineItemsFieldName}.${index}.totalCube`}
            label={showLabels ? 'Total Cube (m3)' : undefined}
            {...commonNumberProps}
          />
        </Grid>
      ) : (
        <Grid item xs={2}>
          <PTextFieldReadOnly label={showLabels ? 'Cube(m3)' : undefined} size={size} value={cube.toFixed(4)} />
        </Grid>
      )}
    </>
  );
};

const TotalsRow = () => {
  const { values } = useFormikContext<ConsignmentFormValues>();
  const { cubeTotal, unitTotal, weightTotal } = useGetRowTotals(values as Consignment);

  return (
    <Grid container spacing={0.5} columns={13.5} marginBottom={2.5}>
      <Grid item xs={2}>
        Stock Totals:
      </Grid>
      <Grid item xs={1.5} sx={{ paddingLeft: '30px !important' }}>
        {unitTotal}
      </Grid>
      <Grid item xs={2} sx={{ paddingLeft: '30px !important' }}>
        {weightTotal} kg
      </Grid>
      {/* {cubeTotal ? null : (
        <> */}
          <Grid item xs={2} sx={{ paddingLeft: '30px !important' }}></Grid>
          <Grid item xs={2} sx={{ paddingLeft: '30px !important' }}></Grid>
          <Grid item xs={2} sx={{ paddingLeft: '30px !important' }}></Grid>
        {/* </>
      )} */}
      <Grid item xs={2} sx={{ paddingLeft: '30px !important' }}>
        {cubeTotal.toFixed(4)}
      </Grid>
    </Grid>
  );
};

const BooleanOptions = () => {
  const { values, setFieldValue, handleChange, initialValues } = useFormikContext<ConsignmentFormValues>();
  useEffect(() => {
    if (initialValues.orderType !== values.orderType && values.orderType === 'drop-off') {
      if (values.orderType === 'drop-off') {
        setFieldValue('signatureRequired', true);
        setFieldValue('photoPodRequired', true);
      }
    } else if (values.orderType === 'pick-up') {
      setFieldValue('signatureRequired', false);
      setFieldValue('photoPodRequired', false);
    }
  }, [setFieldValue, values.orderType, initialValues.orderType]);
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <PCheckbox disabled={values.orderType === 'pick-up'} name="signatureRequired" label="Signature Required" />
        </Grid>
        <Grid item xs={4}>
          <PCheckbox name="customsMpiFoodSafety" label="Customs/MPI/Food Safety" />
        </Grid>
        <Grid item xs={4}>
          <PCheckbox disabled={values.orderType === 'pick-up'} name="photoPodRequired" label="Photo POD Required" />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <PCheckbox name="fragile" label="Fragile" />
        </Grid>
        <Grid item xs={4}>
          <PCheckbox name="urgent" label="Urgent" />
        </Grid>
        <Grid item xs={4}>
          <PCheckbox name="saturdayDelivery" label="Saturday Delivery" />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <PCheckbox name="temperatureControlRequired" label="Temperature Control Required" />
        </Grid>
        <Grid item xs={4}>
          <PCheckbox name="tailLiftRequired" label="Tail Lift Required" />
        </Grid>
      </Grid>
      <RadioGroup value={values.goodsType} name="goodsType" onChange={handleChange}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <FormControlLabel
              value="general"
              control={
                <Radio
                  icon={<CircleIcon fontSize="large" sx={{ color: Colors.grey.bg }} />}
                  checkedIcon={<CheckCircleIcon fontSize="large" sx={{ color: Colors.greyscale.offBlack }} />}
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: 35,
                    },
                  }}
                />
              }
              label="General Goods"
            />
          </Grid>
          <Grid item xs={4}>
            <FormControlLabel
              value="dangerous"
              control={
                <Radio
                  icon={<CircleIcon fontSize="large" sx={{ color: Colors.grey.bg }} />}
                  checkedIcon={<CheckCircleIcon fontSize="large" sx={{ color: Colors.greyscale.offBlack }} />}
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: 35,
                    },
                  }}
                />
              }
              label="Dangerous Goods"
            />
          </Grid>
        </Grid>
      </RadioGroup>
    </>
  );
};

export default OrderDetailsSection;
