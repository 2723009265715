import { Box } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';
import { Formik, FormikHelpers } from 'formik';
import { PTextField } from '../../pegasus/PTextField';
import { User } from '../../../types';

type Props = {
  initialValues: Partial<User>;
  onSubmit: (values: Partial<User>, formikHelpers: FormikHelpers<Partial<User>>) => void | Promise<User>;
  children: React.ReactNode;
};

const EditProfileForm = ({ initialValues, onSubmit, children }: Props) => {
  const classes = useStyles();
  const validate = (values: Partial<User>) => {
    // eslint-disable-next-line no-unused-vars
    const errors: Partial<{ [key in keyof User]: string }> = {};
    (Object.keys(initialValues) as Array<keyof typeof initialValues>).forEach((k) => {
      if (!values[k]) {
        errors[k] = 'Required';
      }
    });
    return errors;
  };

  return (
    <Formik initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
      {({ handleSubmit }) => {
        return (
          <>
            <form onSubmit={handleSubmit} className={classes.form}>
              <PTextField name="firstName" fullWidth label="First Name" />
              <PTextField name="lastName" fullWidth label="Last Name" />
              <PTextField name="email" fullWidth label="Email" type="email" />
              <Box marginTop={4}>{children}</Box>
            </form>
          </>
        );
      }}
    </Formik>
  );
};

export default EditProfileForm;

const useStyles = makeStyles(() =>
  createStyles({
    form: {
      flex: 1,
    },
  })
);
