import React, { useContext } from 'react';
import { Consignment } from '../../../../../types';
import { Box, IconButton, Typography } from '@mui/material';
import useGetStatus from '../../../../hooks/useGetStatus';
import useGetDriverName from '../../../../hooks/useGetDriverName';
import { PIcon } from '../../../../pegasus/PIcon';
import { Colors } from '../../../../pegasus/Colors';
import ModalContext, { ConfirmationServiceContext } from '../../../../context/modal';
import useConsignmentApiRoutes from '../../../../hooks/api/useConsignmentApiRoutes';
import SnackBarContext from '../../../../context/snackbar';
import ConsignmentContext from '../../../../context/consignments';
import useGetContextColor from '../../../../hooks/useGetContextColor';
import useGetConsignmentAddressObject from '../../../../hooks/useGetConsignmentAddressObject';

export const StatusField = ({ consignment }: { consignment: Consignment }) => {
  const getStatus = useGetStatus();
  const status = getStatus(consignment);
  const contextColor = useGetContextColor(consignment);
  return (
    <Typography variant="linkXSmall" color={contextColor}>
      {status?.name}
    </Typography>
  );
};
export const DriverText = ({ consignment }: { consignment: Consignment }) => {
  const driverText = useGetDriverName(consignment);
  return <Typography variant="linkXSmall">{driverText}</Typography>;
};
export const CompanyText = ({ consignment }: { consignment: Consignment }) => {
  const getConsignmentAddressObject = useGetConsignmentAddressObject();
  const addressObject = getConsignmentAddressObject(consignment);
  return <Typography variant="linkXSmall">{addressObject?.companyName}</Typography>;
};
export const ViewButton = ({ consignment }: { consignment: Consignment }) => {
  const { openDrawer, setConsignment } = useContext(ModalContext);
  const handleClick = () => {
    setConsignment(consignment);
    openDrawer('view-consignment');
  };
  return (
    <Box display="flex" alignItems="center" height="100%">
      <IconButton size="small" onClick={handleClick}>
        <PIcon name="forward" sx={{ color: Colors.primary.dark }} />
      </IconButton>
    </Box>
  );
};
export const ArchiveButton = ({ consignment }: { consignment: Consignment }) => {
  const contextColor = useGetContextColor(consignment);
  const { showSnack } = useContext(SnackBarContext);
  const { refreshConsignments } = useContext(ConsignmentContext);
  const { update } = useConsignmentApiRoutes();
  const { showConfirmationModal, setOpenId } = useContext(ConfirmationServiceContext);

  const handleClick = async () => {
    const res = await showConfirmationModal({
      title: 'Move To Archives',
      contentText: 'Do you wish to move this consignment into the Archives?',
    });
    if (res && res.confirmed === true) {
      try {
        await update({ ...consignment, archived: true });
        await refreshConsignments();
        showSnack('Consignment Archived!', 'success');
        setOpenId(undefined);
      } catch (error) {
        // setOpenId(undefined);
      }
    }
  };

  return (
    <Box display="flex" alignItems="center" height="100%">
      <IconButton size="small" onClick={handleClick}>
        <PIcon name="bag" sx={{ color: contextColor }} />
      </IconButton>
    </Box>
  );
};
export const CheckInField = ({ consignment }: { consignment: Consignment }) => {
  const contextColor = useGetContextColor(consignment);
  return consignment.checkInSheet?.screenAtDate ? (
    <Box>
      <PIcon name="check" sx={{ color: contextColor }} />
    </Box>
  ) : (
    <Typography variant="linkXSmall" color={contextColor}>
      -
    </Typography>
  );
};
