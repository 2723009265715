import { Box, Button } from '@mui/material';
import React, { useContext } from 'react';
import ModalContext from '../../../context/modal';
import { FormikHelpers } from 'formik';
import SnackBarContext from '../../../context/snackbar';
import ConsignmentForm, { ConsignmentFormValues } from './ConsignmentForm';
import { SubmitButton } from '../../../pegasus/PSubmit';
import useConsignmentApiRoutes from '../../../hooks/api/useConsignmentApiRoutes';
import { Consignment } from '../../../../types';
import { Colors } from '../../../pegasus/Colors';

const EditConsignmentModal = () => {
  const { closeDrawer, consignment } = useContext(ModalContext);
  const { showSnack } = useContext(SnackBarContext);
  const { update } = useConsignmentApiRoutes();

  const handleSubmit = (values: ConsignmentFormValues, { setSubmitting }: FormikHelpers<ConsignmentFormValues>) => {
    update(values as Consignment)
      .then(() => {
        setSubmitting(false);
        showSnack('Consignment Updated!', 'success');
        closeDrawer('edit-consignment');
      })
      .catch(() => setSubmitting(false));
  };

  return consignment ? (
    <Box color={Colors.greyscale.offBlack} display="flex" flexDirection="column" flex={1}>
      <ConsignmentForm
        onSubmit={handleSubmit}
        initialValues={{
          ...consignment,
          sender: {
            ...consignment.sender,
            state: consignment.sender.state ? consignment.sender.state : '',
            originCountryCode: consignment.sender.originCountryCode ? consignment.sender.originCountryCode : '',
          },
          receiver: {
            ...consignment.receiver,
            state: consignment.receiver.state ? consignment.receiver.state : '',
            originCountryCode: consignment.receiver.originCountryCode ? consignment.receiver.originCountryCode : '',
          },
        }}
        readOnlyValues={['consignmentId']}
      >
        <SubmitButton sx={{ marginRight: 1, backgroundColor: Colors.greyscale.offBlack }}>Save Edits</SubmitButton>
        <Button variant="outlined" color="inherit" onClick={() => closeDrawer('edit-consignment')}>
          Cancel
        </Button>
      </ConsignmentForm>
    </Box>
  ) : null;
};

export default EditConsignmentModal;
