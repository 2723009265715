import React, { useContext, useState, useEffect, useCallback } from 'react';
import { Button, Grid, MenuItem, Typography } from '@mui/material';
import { PTextFieldMemo, PTextFieldReadOnly, RequiredFormikTextFields } from '../../../pegasus/PTextField';
import { PSelect } from '../../../pegasus/PSelect';
import { areaZones, Consignment } from '../../../../types';
import { ConsignmentFormValues } from './ConsignmentForm';
import UserContext from '../../../context/user';
import { AddressBook } from '../../../../types';
import { get, isEmpty, isEqual, pick } from 'lodash';
import useAddressBookApiRoutes from '../../../hooks/api/useAddressBookApiRoutes';
import SnackBarContext from '../../../context/snackbar';
import { PIcon } from '../../../pegasus/PIcon';
import AddressSelect from '../../../components/form/AddressSelect';
import { PCheckbox } from '../../../pegasus/PCheckbox';
import statuses from '../../../constants/statuses';
import { PhoneNumberInput } from '../../../pegasus/PPhoneNumberInput';
import useGetStatus from '../../../hooks/useGetStatus';
import { PDateTimePicker } from '../../../pegasus/PDateTimePicker';
import PTagsInput from '../../../pegasus/PTagsInput';

const SenderSection = ({
  values,
  ...formikProps
}: RequiredFormikTextFields & { values: Partial<ConsignmentFormValues> }) => {
  const disabled = !(values.status === 'onHold' || values.status === 'unassigned');
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [canSaveAddressBook, setCanSaveAddressBook] = useState(false);
  const [addressBook, setAddressBook] = useState<Partial<AddressBook>>({});
  const { create } = useAddressBookApiRoutes();
  const { showSnack } = useContext(SnackBarContext);
  const getStatus = useGetStatus();
  const status = getStatus(values as Consignment);

  useEffect(() => {
    if (user && user._id) {
      const addressBook: Partial<AddressBook> = {
        userId: user._id,
        ...values.sender,
      };
      setAddressBook(addressBook);
    }
  }, [user, values]);

  const checkCanSaveAddressBook = useCallback((addressBook: Partial<AddressBook>): boolean => {
    let result = false;
    if (addressBook) {
      result = true;
      (Object.keys(addressBook) as Array<keyof typeof addressBook>).forEach((key) => {
        if (
          isEmpty(addressBook[key]) &&
          !['milestones', 'labels', 'createdAt', 'updatedAt', 'areaZone', 'additionalInfo'].includes(key)
        ) {
          result = false;
        }
        if (key === 'location' && addressBook[key] && isEmpty(addressBook['address'])) {
          result = false;
        }
      });
    }
    return result;
  }, []);

  useEffect(() => {
    const checkCanSave = checkCanSaveAddressBook(addressBook);
    setCanSaveAddressBook(checkCanSave);
  }, [addressBook, checkCanSaveAddressBook]);

  const handleSaveAddressBook = () => {
    if (canSaveAddressBook) {
      setLoading(true);
      create(addressBook)
        .then(() => {
          showSnack('Address Saved!', 'success');
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => setLoading(false));
    } else {
      return;
    }
  };

  return (
    <>
      <Typography variant="displayXSmallBold" color="inherit" mb={3}>
        Sender
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <PTextFieldMemo
            value={get(values, 'sender.companyName')}
            disabled={disabled}
            name="sender.companyName"
            fullWidth
            label="Company Name"
            {...formikProps}
          />
        </Grid>
        <Grid item xs={8}>
          <AddressSelect addressBookFieldName="sender" name="sender.address" fullWidth label="Address" />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PTextFieldMemo
            value={get(values, 'sender.additionalInfo')}
            disabled={disabled}
            name="sender.additionalInfo"
            fullWidth
            label="Additional Address Information"
            {...formikProps}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <PTextFieldMemo
            value={get(values, 'sender.contactName')}
            disabled={disabled}
            name="sender.contactName"
            fullWidth
            label="Contact Name"
            {...formikProps}
          />
        </Grid>
        <Grid item xs={4}>
          <PhoneNumberInput disabled={disabled} name="sender.phone" fullWidth label="Phone Number" />
        </Grid>
        <Grid item xs={4}>
          <PTextFieldMemo
            value={get(values, 'sender.email')}
            disabled={disabled}
            name="sender.email"
            fullWidth
            label="Email"
            type="email"
            {...formikProps}
          />
        </Grid>
        <Grid item xs={4} mb={4}>
          <PCheckbox disabled={disabled} name="sender.milestones" label="Milestone Emails" />
        </Grid>
        <Grid item xs={4} mb={4}>
          <PCheckbox disabled={disabled} name="sender.labels" label="Email A4 Label" />
        </Grid>
      </Grid>
      {values.orderType !== 'drop-off' ? (
        <>
          <Button
            disabled={loading || !canSaveAddressBook}
            variant={canSaveAddressBook ? 'contained' : 'outlined'}
            color={canSaveAddressBook ? 'primary' : 'inherit'}
            fullWidth
            sx={{ marginBottom: 2.5 }}
            onClick={handleSaveAddressBook}
          >
            <PIcon name="readBook" sx={{ marginRight: 1.5 }} />
            Add to Address Book
          </Button>
        </>
      ) : null}
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <PTagsInput name="sender.contactEmails" label="Additional Email" />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <PSelect disabled={disabled} name="sender.areaZone" fullWidth label="Area Zone">
            {areaZones.map((areaZone) => (
              <MenuItem key={areaZone.code} value={areaZone.code}>
                {areaZone.name}
              </MenuItem>
            ))}
          </PSelect>
        </Grid>
        <Grid item xs={4}>
          {values._id ? (
            <PTextFieldReadOnly fullWidth label="Order Status" value={status?.name || ''} />
          ) : (
            <PSelect name="status" fullWidth label="Order Status">
              {statuses.map((status) => {
                return status.showOnConsignment ? (
                  <MenuItem key={status.code} value={status.code}>
                    {status.name}
                  </MenuItem>
                ) : null;
              })}
            </PSelect>
          )}
        </Grid>
        <Grid item xs={4}>
          <PDateTimePicker name="preferredPickUpTime" fullWidth label="Preferred PickUp Time" />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <PTextFieldMemo
            value={get(values, 'purchaseOrderNumber')}
            name="purchaseOrderNumber"
            fullWidth
            label="Purchase Order Number"
            {...formikProps}
          />
        </Grid>
        <Grid item xs={4}>
          <PTextFieldMemo
            value={get(values, 'supplierName')}
            name="supplierName"
            fullWidth
            label="Supplier Name"
            {...formikProps}
          />
        </Grid>
        <Grid item xs={4}>
          <PTextFieldMemo
            value={get(values, 'reference')}
            name="reference"
            fullWidth
            label="Reference"
            {...formikProps}
          />
        </Grid>
      </Grid>
    </>
  );
};

const formFields = [
  'sender.companyName',
  'sender.address',
  'sender.additionalInfo',
  'sender.contactName',
  'sender.phone',
  'sender.email',
  'sender.milestones',
  'sender.labels',
  'sender.contactEmails',
  'readBook',
  'sender.areaZone',
  'status',
  'preferredPickUpTime',
  'purchaseOrderNumber',
  'supplierName',
  'reference',
];
export default React.memo(SenderSection, (prevProps, nextProps) => {
  return isEqual(pick(prevProps.values, formFields), pick(nextProps.values, formFields));
});

// export default SenderSection;
