import * as React from 'react';
import { Chip, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { useCallback, useState } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import { useFormikContext } from 'formik';
import { ThemedTextFieldProps } from './PTextField';
import get from 'lodash/get';

export default function PTagsInput(props: ThemedTextFieldProps) {
  const { name, ...otherProps } = props;
  const { values, setFieldValue, handleChange } = useFormikContext();
  const [tags, setTags] = useState<string[]>(get(values, name, []));
  const [inputValue, setInputValue] = useState('');

  const handleDelete = (indexTag: number) => {
    const newTags = [...tags];
    newTags.splice(indexTag, 1);
    setTags(newTags);
  };

  const addTag = useCallback(
    (newValue: string) => {
      if (newValue) {
        const newTags = [...tags];
        const duplicatedValues = newTags.indexOf(newValue);
        if (duplicatedValues !== -1) {
          setInputValue('');
          return;
        }
        newTags.push(newValue.trim());
        setTags(newTags);
        setFieldValue(name, newTags);
        setInputValue('');
      }
    },
    [name, setFieldValue, tags]
  );

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (
      (event.key === 'Enter' || event.key === 'ArrowRight' || event.key === 'Tab' || event.key === 'ArrowDown') &&
      inputValue
    ) {
      addTag(inputValue);
    }
  };

  const handleInputChange = (value: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInputValue(value.target.value);
  };

  const handleBlur = ({ target: { value } }: React.FocusEvent<HTMLInputElement>) => {
    addTag(value);
  };

  return (
    <Box>
      <TextField
        fullWidth
        variant="filled"
        style={{ marginBottom: 20 }}
        value={inputValue}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        onChange={(event) => {
          handleInputChange(event);
          handleChange(event);
        }}
        InputProps={{
          autoComplete: 'do-not-autofill',
          disableUnderline: true,
          startAdornment:
            tags.length > 0
              ? tags.map((tag, index) => (
                  <Chip
                    key={index}
                    style={{ marginLeft: index === 0 ? 8 : 2, marginRight: 2, marginTop: 16, height: 26 }}
                    label={tag}
                    color="default"
                    deleteIcon={<CancelIcon onMouseDown={(event) => event.stopPropagation()} />}
                    onDelete={() => handleDelete(index)}
                  />
                ))
              : null,
        }}
        {...otherProps}
      />
    </Box>
  );
}
