import React from 'react';
import { Marker, InfoBox } from '@react-google-maps/api';
import { renderToStaticMarkup } from 'react-dom/server';
import { Consignment } from '../../../../../types';
import { InfoBoxContentsWrapper, InfoWindowWidth } from './InfoBoxContentsWrapper';
import { Button, Divider, Typography } from '@mui/material';
import { Colors } from '../../../../pegasus/Colors';
import useGetConsignmentLocation from '../../../../hooks/useGetConsignmentLocation';
import { format } from 'date-fns';
import { AssignDriverButton } from '../AssignDriverButton';
import DropOffAssigned from '../../../../imgs/map-markers/DropOffAssigned.svg';
import DropOffUnAssigned from '../../../../imgs/map-markers/DropOffUnAssigned.svg';
import PickUpAssigned from '../../../../imgs/map-markers/PickUpAssigned.svg';
import PickUpUnAssigned from '../../../../imgs/map-markers/PickUpUnAssigned.svg';
import TransferAssigned from '../../../../imgs/map-markers/TransferAssigned.svg';
import TransferUnAssigned from '../../../../imgs/map-markers/TransferUnAssigned.svg';
import useConsignmentActions from '../../../../hooks/useConsignmentActions';
import useGetContextColor from '../../../../hooks/useGetContextColor';
import useGetConsignmentAddressObject from '../../../../hooks/useGetConsignmentAddressObject';

const renderConsignmentMarkerUrl = (consignment: Consignment) => {
  let MarkerComponent: string;
  if (consignment.orderType === 'pick-up' && consignment.status === 'unassigned') {
    MarkerComponent = PickUpUnAssigned;
  } else if (consignment.orderType === 'pick-up' && consignment.status !== 'unassigned') {
    MarkerComponent = PickUpAssigned;
  } else if (
    consignment.orderType === 'transfer' &&
    (consignment.status === 'unassigned' || consignment.status === 'atDepot')
  ) {
    MarkerComponent = TransferUnAssigned;
  } else if (consignment.orderType === 'transfer' && consignment.status !== 'unassigned') {
    MarkerComponent = TransferAssigned;
  } else if (consignment.orderType === 'drop-off' && consignment.status === 'unassigned') {
    MarkerComponent = DropOffUnAssigned;
  } else {
    MarkerComponent = DropOffAssigned;
  }

  const markerSvgString = encodeURIComponent(renderToStaticMarkup(<MarkerComponent />));
  return `data:image/svg+xml;charset=utf-8,${markerSvgString}`;
};

export const ConsignmentMarker = ({
  consignment,
  onClick,
  isActive,
  onCloseInfoBox,
}: {
  consignment: Consignment;
  onClick: (consignmentId: string) => void;
  isActive: boolean;
  onCloseInfoBox: () => void;
}) => {
  const getConsignmentLocation = useGetConsignmentLocation();
  const { cancelConsignment } = useConsignmentActions();
  const getConsignmentAddressObject = useGetConsignmentAddressObject();
  const addressObject = getConsignmentAddressObject(consignment);
  const addressObjectText = addressObject === consignment.receiver ? 'DESTINATION' : 'PICK UP';

  const position = getConsignmentLocation(consignment);
  const contextColor = useGetContextColor(consignment);

  const markerHeight = consignment.status === 'unassigned' ? 71 : 60;

  const handleMarkerClick = () => {
    onClick(consignment._id);
  };
  const handleCancel = () => {
    cancelConsignment(consignment);
  };

  return (
    <Marker
      position={position}
      icon={{
        // anchor: new google.maps.Point(16, 16),
        url: renderConsignmentMarkerUrl(consignment),
      }}
      onClick={handleMarkerClick}
    >
      {isActive ? (
        <InfoBox
          onCloseClick={onCloseInfoBox}
          options={{
            alignBottom: true,
            maxWidth: InfoWindowWidth,
            pixelOffset: new google.maps.Size(-0.5 * InfoWindowWidth, -1 * markerHeight),
          }}
        >
          <InfoBoxContentsWrapper>
            <Typography display="block" variant="textXSmall" color={Colors.greyscale.offBlack}>
              {format(new Date(consignment.createdAt), 'd MMM, h:mm aa')}
            </Typography>
            <Typography variant="textXSmall" color={contextColor}>
              #{consignment.consignmentId}
            </Typography>
            <Divider sx={{ borderColor: 'white', my: 0.5 }} />
            <Typography display="block" variant="textXSmall" color={Colors.greyscale.offBlack}>
              {addressObjectText}
            </Typography>
            <Typography display="block" variant="textXSmall" color={Colors.greyscale.offBlack}>
              {addressObject?.address}
            </Typography>
            <AssignDriverButton consignment={consignment} />
            {consignment.status === 'assigned' || consignment.status === 'assignedLeg2' ? (
              <Button size="small" sx={{ color: contextColor, textDecoration: 'underline' }} onClick={handleCancel}>
                Cancel
              </Button>
            ) : null}
          </InfoBoxContentsWrapper>
        </InfoBox>
      ) : null}
    </Marker>
  );
};
