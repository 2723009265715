import * as React from 'react';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { Colors } from './Colors';
import { SxProps, Theme } from '@mui/system';
import { useFormikContext } from 'formik';
import get from 'lodash/get';

type Props = SwitchProps & {
  name: string;
  label: string;
  fullWidth?: boolean;
};
const IOSSwitch = styled(({ name, ...otherProps }: SwitchProps & { name: string }) => {
  const { handleBlur, handleChange, initialValues } = useFormikContext();
  return (
    <Switch
      defaultChecked={get(initialValues, name)}
      name={name}
      onChange={handleChange}
      onBlur={handleBlur}
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...otherProps}
    />
  );
})(({ theme }) => ({
  width: 64,
  height: 32,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(32px)',
      color: theme.palette.common.white,
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? Colors.primary.darkMode : theme.palette.primary.main,
        opacity: 1,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 28,
    height: 28,
  },
  '& .MuiSwitch-track': {
    borderRadius: 32 / 2,
    backgroundColor: Colors.greyscale.line,
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

export default function PSwitch({ label, fullWidth, ...otherProps }: Props) {
  let sx: SxProps<Theme> = { marginBottom: 2.5 };
  if (fullWidth) {
    sx = { ...sx, width: '100%', display: 'flex', justifyContent: 'space-between', marginLeft: 0, marginRight: 0 };
  }
  return (
    <FormControlLabel
      sx={sx}
      control={<IOSSwitch sx={{ ml: 1 }} {...otherProps} />}
      label={label}
      labelPlacement="start"
    />
  );
}
