import { useContext } from 'react';
import { User } from '../../types';
import ConsignmentContext from '../context/consignments';

type Field = 'assignedToDriverLeg2Id' | 'assignedToDriverLeg1Id';
export default function useFilterDriverConsignments(driver?: User) {
  const { consignments } = useContext(ConsignmentContext);
  return driver
    ? consignments.filter((c) => {
        const field: Field = ['assignedLeg2', 'inTransitLeg2'].includes(c.status)
          ? 'assignedToDriverLeg2Id'
          : 'assignedToDriverLeg1Id';

        return ['assigned', 'inTransit', 'assignedLeg2', 'inTransitLeg2'].includes(c.status) && c[field] === driver._id;
      })
    : [];
}
