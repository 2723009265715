import React, { useContext } from 'react';
import { FormikHelpers } from 'formik';
import { SubmitButton } from '../../../pegasus/PSubmit';
import { User } from '../../../../types';
import SnackBarContext from '../../../context/snackbar';
import useUserApiRoutes from '../../../hooks/api/useUserApiRoutes';
import UserForm from '../../Admin/user/UserForm';
import DepotContext from '../../../context/depot';

const NewDriverModal = ({ setOpen }: { setOpen: React.Dispatch<React.SetStateAction<boolean>> }) => {
  const { inviteDriver } = useUserApiRoutes();
  const { showSnack } = useContext(SnackBarContext);
  const { setDrivers, depot } = useContext(DepotContext);

  const handleSubmit = (values: Partial<User>, { setSubmitting }: FormikHelpers<Partial<User>>) => {
    inviteDriver(values)
      .then((driver) => {
        setSubmitting(false);
        setDrivers((drivers) => [driver, ...drivers]);
        showSnack('Driver Invited!', 'success');
        setOpen(false);
      })
      .catch(() => setSubmitting(false));
  };

  return (
    <UserForm
      onSubmit={handleSubmit}
      initialValues={{
        type: 'driver',
        firstName: '',
        lastName: '',
        phone: '',
        countryCode: depot?.countryCode,
        depots: depot?._id ? [depot?._id] : [],
      }}
    >
      <SubmitButton color="primary" sx={{ marginRight: 1 }}>
        Invite To Team
      </SubmitButton>
    </UserForm>
  );
};

export default NewDriverModal;
