import React, { useContext } from 'react';
import { Box, Divider, Grid } from '@mui/material';
import { format } from 'date-fns';
import ModalContext from '../../../context/modal';
import { useGetDriverFullName } from '../../../hooks/useGetDriverName';
import ReceiverSectionReadOnly from './ReceiverSectionReadOnly';
import { ReadOnlyField, ReadOnlyFieldProps } from './ReadOnlyField';
import SenderSectionReadOnly from './SenderSectionReadOnly';
import OrderDetailsSectionReadOnly from './OrderDetailsSectionReadOnly';
import OrderDeadRunsSection from './OrderDeadRunsSection';
import CheckInSignature from './CheckInSignature';
import ProofOfDelivery from './ProofOfDelivery';
import { Colors } from '../../../pegasus/Colors';
import useFormattedSeconds from '../../../hooks/useFormattedSeconds';

export const TabConsignment = ({ contextColor }: { contextColor: string }) => {
  const { consignment } = useContext(ModalContext);
  const driverName = useGetDriverFullName(consignment);
  const formattedSeconds = useFormattedSeconds(consignment?.penaltyTimeAdded || 0);

  return consignment ? (
    <>
      <Grid container columns={5} mb={5}>
        <Grid item xs>
          <ReadOnlyField
            label="PREFERRED PICK-UP"
            value={
              consignment.preferredPickUpTime
                ? format(new Date(consignment.preferredPickUpTime), 'd MMM, h:mm aa')
                : '-'
            }
          />
        </Grid>
        <Grid item xs>
          <ReadOnlyField
            label="PREFERRED DELIVERY"
            value={
              consignment.preferredDeliveryTime
                ? format(new Date(consignment.preferredDeliveryTime), 'd MMM, h:mm aa')
                : '-'
            }
          />
        </Grid>
        <Grid item xs>
          <ReadOnlyField
            label="ESTIMATED DELIVERY"
            value={
              consignment.estimatedDeliveryTime
                ? format(new Date(consignment.estimatedDeliveryTime), 'd MMM, h:mm aa')
                : '-'
            }
          />
        </Grid>
        <Grid item xs={2}>
          <ReadOnlyField label="DRIVER" value={driverName || '-'} />
        </Grid>
      </Grid>

      <Grid container columns={5}>
        <Grid item xs={3}>
          <ReceiverSectionReadOnly contextColor={contextColor} />
          <SenderSectionReadOnly contextColor={contextColor} />
        </Grid>
        <Grid item xs={2} display="flex">
          <Box display="flex" flex={1} flexDirection="column">
            <Box flex={1}>
              <CheckInSignature sx={{ marginBottom: 2 }} consignment={consignment} />
              <ProofOfDelivery consignment={consignment} />
            </Box>
            {consignment.driverNotes ? (
              <ReadOnlyFieldWithMargin label="DRIVER NOTES" value={consignment.driverNotes} />
            ) : null}
            {consignment.driverNotesLeg2 ? (
              <ReadOnlyFieldWithMargin label="DRIVER NOTES LEG 2" value={consignment.driverNotesLeg2} />
            ) : null}
            {consignment.penaltyTimeAdded ? (
              <ReadOnlyFieldWithMargin
                label="PENALTY TIME ADDED"
                value={formattedSeconds}
                textColor={Colors.error.main}
              />
            ) : null}
          </Box>
        </Grid>
      </Grid>
      <OrderDeadRunsSection contextColor={contextColor} />
      <Divider />
      <OrderDetailsSectionReadOnly contextColor={contextColor} />
    </>
  ) : null;
};

const ReadOnlyFieldWithMargin = (props: ReadOnlyFieldProps) => (
  <Box mb={5}>
    <ReadOnlyField {...props} />
  </Box>
);
