import { Consignment, User } from '../../types';

export const getDriverInitials = (driver: User) => {
  return `${driver.firstName[0]}${driver.lastName[0]}`;
};

export const getShortName = (user: User) => {
  return `${user.firstName} ${user.lastName[0]}.`;
};
export const getFullName = (user: User) => {
  return `${user.firstName} ${user.lastName}`;
};

export const useGetDriver = (consignment: Consignment | undefined): User | undefined => {
  return (consignment?.assignedToDriverLeg2 as User) || (consignment?.assignedToDriverLeg1 as User);
};
export const useGetDriverFullName = (consignment: Consignment | undefined): string => {
  const driver = useGetDriver(consignment);
  return driver ? getFullName(driver) : '';
};

export default function useGetDriverName(consignment: Consignment) {
  let driverText = 'Unassigned'; //init
  if (consignment.assignedToDriverLeg2 && (consignment.assignedToDriverLeg2 as User).firstName) {
    driverText = getShortName(consignment.assignedToDriverLeg2 as User);
  } else if (consignment.assignedToDriverLeg1 && (consignment.assignedToDriverLeg1 as User).firstName) {
    driverText = getShortName(consignment.assignedToDriverLeg1 as User);
  }

  return driverText;
}
