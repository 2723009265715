import React, { useContext, useState } from 'react';
import { GoogleMap } from '@react-google-maps/api';
import { Box, MenuItem, TextField, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import DepotContext from '../../../context/depot';
import { DriverMarker } from './map/DriverMarker';
import { ConsignmentMarker } from './map/ConsignmentMarker';
import useGetConsignmentLocation from '../../../hooks/useGetConsignmentLocation';
import ConsignmentContext from '../../../context/consignments';
import { OrderType } from '../../../../types';
import orderTypes from '../../../constants/orderTypes';
import { Colors } from '../../../pegasus/Colors';
import { VehicleMarker } from './map/VehicleMarker';

const center = {
  lat: -36.979984387467205,
  lng: 174.77844557333128,
};

const styles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
    },
    mapContainer: {
      flex: 1,
    },
    filter: {
      position: 'absolute',
      top: theme.spacing(2),
      left: theme.spacing(2),
      zIndex: 1,
      width: 200,
      '& .MuiFilledInput-root': {
        backgroundColor: Colors.greyscale.offWhite,
      },
    },
  })
);

type MapFilter = OrderType | 'view-all';

// eslint-disable-next-line react/display-name
export const DriverMap = React.memo(() => {
  const classes = styles();
  const { drivers, vehicles } = useContext(DepotContext);
  const { consignments } = useContext(ConsignmentContext);
  const [activeMarkerId, setActiveMarkerId] = useState<string>();
  const [, setMap] = React.useState<google.maps.Map | null>(null);
  const [mapFilter, setMapFilter] = useState<MapFilter>('view-all');
  const getConsignmentLocation = useGetConsignmentLocation();

  const onLoad = React.useCallback(
    function callback(map: google.maps.Map) {
      const bounds = new google.maps.LatLngBounds();
      // include the center
      bounds.extend(new google.maps.LatLng(center));
      // include all consignments in the bounds
      consignments.forEach((consignment) => {
        bounds.extend(new google.maps.LatLng(getConsignmentLocation(consignment)));
      });
      // include all drivers in the bounds
      drivers.forEach((driver) => {
        if (driver.driverInformation?.lastKnownPosition?.lat && driver.driverInformation?.lastKnownPosition?.lng) {
          bounds.extend(new google.maps.LatLng(driver.driverInformation?.lastKnownPosition));
        }
      });
      map.fitBounds(bounds);
      setMap(map);
    },
    [consignments, drivers, getConsignmentLocation]
  );

  const onUnmount = React.useCallback(function callback() {
    setMap(null);
  }, []);

  const handleMarkerClick = (id: string) => {
    setActiveMarkerId(id);
  };

  const handleCloseInfoBox = () => {
    setActiveMarkerId(undefined);
  };

  const handleFilterChange = ({ target: { value } }: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setMapFilter(value);
  };

  return (
    <Box className={classes.container}>
      <TextField
        value={mapFilter}
        label="Order Type"
        variant="filled"
        select
        classes={{ root: classes.filter }}
        onChange={handleFilterChange}
        InputProps={{
          disableUnderline: true,
        }}
      >
        <MenuItem value="view-all">View All</MenuItem>
        {orderTypes.map((orderType) => (
          <MenuItem key={orderType.code} value={orderType.code}>
            {orderType.name}
          </MenuItem>
        ))}
      </TextField>
      <GoogleMap
        mapContainerClassName={classes.mapContainer}
        center={center}
        zoom={15}
        onLoad={onLoad}
        onUnmount={onUnmount}
        onClick={handleCloseInfoBox}
        // hide the satellite /road map switcher so there is room for the filters
        options={{
          mapTypeControl: false,
          styles: [{ featureType: 'poi', elementType: 'labels', stylers: [{ visibility: 'off' }] }],
        }}
      >
        {drivers.map((driver, index) => (
          <DriverMarker
            key={driver._id}
            driver={driver}
            onClick={handleMarkerClick}
            onCloseInfoBox={handleCloseInfoBox}
            isActive={activeMarkerId === driver._id}
            index={index}
          />
        ))}
        {vehicles.map((vehicle, index) => (
          <VehicleMarker
            key={index}
            vehicle={vehicle}
            onClick={handleMarkerClick}
            onCloseInfoBox={handleCloseInfoBox}
            isActive={activeMarkerId === vehicle.registrationNumber}
          />
        ))}
        {consignments
          .filter((c) => c.status !== 'complete' && (mapFilter === 'view-all' || c.orderType === mapFilter))
          .map((consignment) => (
            <ConsignmentMarker
              key={consignment._id}
              consignment={consignment}
              onClick={handleMarkerClick}
              onCloseInfoBox={handleCloseInfoBox}
              isActive={activeMarkerId === consignment._id}
            />
          ))}
      </GoogleMap>
    </Box>
  );
});
