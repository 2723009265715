import { useCallback } from 'react';
import { User } from '../../../types';
import { REACT_APP_API_BASE_URL } from '../../env';
import { usePublicRoute } from './usePublicRoute';

export default function useUserApiRoutes() {
  const publicRoute = usePublicRoute();

  const invite = useCallback(
    async (user: Partial<User>): Promise<User> => {
      const response = await publicRoute({
        method: 'post',
        url: `${REACT_APP_API_BASE_URL}/users/invite`,
        data: user,
      });
      return response;
    },
    [publicRoute]
  );

  const inviteDriver = useCallback(
    async (user: Partial<User>): Promise<User> => {
      const response = await publicRoute({
        method: 'post',
        url: `${REACT_APP_API_BASE_URL}/users/invite-driver`,
        data: user,
      });
      return response;
    },
    [publicRoute]
  );

  const resendDriverInvite = useCallback(
    async (driverId: string): Promise<{ status: string }> => {
      const response = await publicRoute({
        method: 'post',
        url: `${REACT_APP_API_BASE_URL}/users/${driverId}/resend-driver-invite`,
      });
      return response;
    },
    [publicRoute]
  );

  const list = useCallback(
    async (params?: {
      type?: string;
      driversOnly?: boolean;
      depots?: string[];
      countryCode?: string;
      page?: number;
      itemsPerPage?: number;
      unlimit?: boolean;
    }): Promise<{ users: User[]; total: number }> => {
      const response = await publicRoute({
        method: 'get',
        url: `${REACT_APP_API_BASE_URL}/users`,
        params: params,
      });
      return response;
    },
    [publicRoute]
  );

  const getUser = useCallback(
    async (userId: string): Promise<User> => {
      const response = await publicRoute({
        method: 'get',
        url: `${REACT_APP_API_BASE_URL}/users/${userId}`,
      });
      return response;
    },
    [publicRoute]
  );

  const editProfile = useCallback(
    async (user: Partial<User>): Promise<Partial<User>> => {
      const response = await publicRoute({
        method: 'put',
        url: `${REACT_APP_API_BASE_URL}/users/${user._id}/edit-profile`,
        data: user,
      });
      return response;
    },
    [publicRoute]
  );

  const updateIsActive = useCallback(
    async (userId: string, active: boolean): Promise<Partial<User>> => {
      const response = await publicRoute({
        method: 'patch',
        url: `${REACT_APP_API_BASE_URL}/users/${userId}/update-active`,
        data: { active },
      });
      return response;
    },
    [publicRoute]
  );

  const deleteUserAcccount = useCallback(
    async (userId: string): Promise<Partial<User>> => {
      const response = await publicRoute({
        method: 'delete',
        url: `${REACT_APP_API_BASE_URL}/users/${userId}`,
      });
      return response;
    },
    [publicRoute]
  );

  return { invite, inviteDriver, list, getUser, editProfile, resendDriverInvite, updateIsActive, deleteUserAcccount };
}
