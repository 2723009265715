import React from 'react';
import { Box, Button, Grid, IconButton, Theme, Typography } from '@mui/material';
import { PTextFieldMemo, RequiredFormikTextFields } from '../../../pegasus/PTextField';
import { createStyles, makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import { Colors } from '../../../pegasus/Colors';
import { LineItemRow } from './OrderDetailsSection';
import { PIcon } from '../../../pegasus/PIcon';
import { get } from 'lodash';
import { Consignment } from '../../../../types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginBottom: theme.spacing(3),
    },
    scrollcontainer: {
      overflow: 'auto',
    },
    gridContainer: {
      minWidth: '1000px',
    },
    closeButton: {
      position: 'absolute',
      left: theme.spacing(0.5),
      marginTop: theme.spacing(1),
    },
  })
);

const CheckInTable = ({ values, ...formikProps }: RequiredFormikTextFields & { values: Partial<Consignment> }) => {
  const classes = useStyles();
  const handleDelete = (index: number) => {
    formikProps.setFieldValue(
      'checkInSheet.lineItems',
      values.checkInSheet?.lineItems.filter((l, i) => i !== index)
    );
  };
  const stockTotal = values.checkInSheet?.lineItems.reduce((total, curValue) => total + curValue.units, 0) || 0;

  const addRow = () => {
    formikProps.setFieldValue('checkInSheet.lineItems', [
      ...(values.checkInSheet?.lineItems || []),
      { type: 'carton', units: 0, length: 0, width: 0, height: 0, weight: 0, marks: '' },
    ]);
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.scrollcontainer}>
        <Box className={classes.gridContainer}>
          <Grid container spacing={0.5} columns={16.5} mb={2}>
            <Heading label="Type" columns={2} />
            <Heading label="ITEM #" columns={1.5} />
            <Heading label="Weight (kg)" columns={2} />
            <Heading label="Length (cm)" columns={2} />
            <Heading label="Width (cm)" columns={2} />
            <Heading label="Height (cm)" columns={2} />
            <Heading label="Cube (m3)" columns={2} />
            <Heading label="Marks" columns={3} />
          </Grid>
          {values.checkInSheet?.lineItems?.map((lineItem, index) => {
            const name = `checkInSheet.lineItems.${index}.marks`;
            return (
              <Grid container key={index} spacing={0.5} columns={16.5}>
                <IconButton className={classes.closeButton} onClick={() => handleDelete(index)}>
                  <CloseIcon color="primary" />
                </IconButton>
                <LineItemRow index={index} lineItemsFieldName="checkInSheet.lineItems" showLabels={false} />
                <Grid item xs={3}>
                  <PTextFieldMemo value={get(values, name)} name={name} size="small" {...formikProps} />
                </Grid>
              </Grid>
            );
          })}
          <Grid container spacing={0.5} columns={16.5} mb={2}>
            <Grid item xs={2}>
              <Typography variant="textSmall" color={Colors.greyscale.offBlack}>
                Stock Totals:
              </Typography>
            </Grid>
            <Grid item xs={14.5}>
              <Typography variant="textSmall" color={Colors.greyscale.offBlack} pl={3}>
                {stockTotal}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Button variant="outlined" fullWidth sx={{ marginBottom: 2.5, marginTop: 1 }} onClick={addRow}>
        <PIcon name="plus" sx={{ mr: 1.5 }} />
        Add Another Row
      </Button>
    </Box>
  );
};

const Heading = ({ label, columns }: { label: string; columns: number }) => {
  return (
    <Grid item xs={columns}>
      <Typography variant="textXSmall" color={Colors.greyscale.label}>
        {label}
      </Typography>
    </Grid>
  );
};

export default CheckInTable;
