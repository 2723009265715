import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tab,
  Tabs,
  Theme,
} from '@mui/material';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import Navbar from '../../components/NavBar';
import { Link, useParams } from 'react-router-dom';
import { PIcon } from '../../pegasus/PIcon';
import { Colors } from '../../pegasus/Colors';
import ModalContext from '../../context/modal';
import countries from '../../constants/countries';
import CurrentTable from './tables/CurrentTable';
import IncompleteFailedTable from './tables/IncompleteFailedTable';
import ArchivedTable from './tables/ArchivedTable';
import ScheduledTable from './tables/ScheduledTable';
import orderTypes from '../../constants/orderTypes';
import { ConsignmentProvider } from '../../context/consignments';
import TabLabel from '../../components/TabLabel';
import useShowIndicator from '../../hooks/useShowIndicator';
import SocketContext from '../../context/socket';
import { Search } from '../../components/form/Search';
import { Formik } from 'formik';
import { PDatepicker } from '../../pegasus/PDatepicker';
import CountrySelect from '../../components/form/CountrySelect';
import useConsignmentApiRoutes from '../../hooks/api/useConsignmentApiRoutes';
import OrderTypeSelect from '../../components/form/OrderTypeSelect';
import { DEFAULT_PAGE, ITEM_PER_PAGE } from '../../constants/table';
import CompletedTable from './tables/CompletedTable';
import DispatchViewButton from '../../components/DispatchViewButton';

const styles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: Colors.primary.background,
      flex: 1,
    },
    tabContent: {
      paddingTop: theme.spacing(4),
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
    },
    darkButton: {
      backgroundColor: Colors.primary.darkMode,
      marginRight: theme.spacing(1),
    },
    select: {
      width: 200,
    },
  })
);

const OpsDashboard = () => {
  const classes = styles();
  const { countryCode = '' } = useParams<'countryCode'>();
  const { openDrawer, setCountry } = useContext(ModalContext);
  const [orderTypeCode, setOrderTypeCode] = useState('');
  const { setUpdatedStatusCodes } = useContext(SocketContext);
  const [filterArchived, setFilterArchived] = useState<{
    searchText: string;
    dataFrom: number | string;
    dataTo: number | string;
    countryCode: string;
  }>({
    searchText: '',
    dataFrom: 'undefined',
    dataTo: 'undefined',
    countryCode: countryCode,
  });
  const [filterRecentlyCompleted, setFilterRecentlyCompleted] = useState<{
    orderType: string;
    dataFrom: number | string;
    dataTo: number | string;
  }>({
    orderType: '',
    dataFrom: 'undefined',
    dataTo: 'undefined',
  });
  const { reportCsvArchive } = useConsignmentApiRoutes();

  const tabs = useMemo<
    {
      key: string;
      name: string;
      icon: JSX.Element;
      indicatorStatuses: string[];
      component: JSX.Element | string;
    }[]
  >(
    () => [
      {
        key: 'current',
        name: 'Current',
        icon: <PIcon sx={{ marginRight: 1, marginTop: 0.5, marginBottom: 0.5 }} name="truck" />,
        indicatorStatuses: ['assigned', 'inTransit', 'assignedLeg2', 'inTransitLeg2'],
        component: (
          <ConsignmentProvider
            queryParams={{
              countryCode: countryCode,
              status: ['assigned', 'inTransit', 'assignedLeg2', 'inTransitLeg2'],
              orderType: orderTypeCode,
              archived: false,
              page: DEFAULT_PAGE,
              itemsPerPage: ITEM_PER_PAGE,
            }}
          >
            <CurrentTable />
          </ConsignmentProvider>
        ),
      },
      {
        key: 'scheduled',
        name: 'Scheduled',
        icon: <PIcon sx={{ marginRight: 1, marginTop: 0.5, marginBottom: 0.5 }} name="time" />,
        indicatorStatuses: ['onHold', 'unassigned', 'atDepot'],
        component: (
          <ConsignmentProvider
            queryParams={{
              countryCode: countryCode,
              status: ['onHold', 'unassigned', 'atDepot'],
              archived: false,
              page: DEFAULT_PAGE,
              itemsPerPage: ITEM_PER_PAGE,
            }}
          >
            <ScheduledTable />
          </ConsignmentProvider>
        ),
      },
      {
        key: 'incompleteFailed',
        name: 'Incomplete/Failed',
        icon: <PIcon sx={{ marginRight: 1, marginTop: 0.5, marginBottom: 0.5 }} name="flag" />,
        indicatorStatuses: ['failed', 'inComplete'],
        component: (
          <ConsignmentProvider
            queryParams={{
              countryCode: countryCode,
              status: ['failed', 'inComplete'],
              archived: false,
              page: DEFAULT_PAGE,
              itemsPerPage: ITEM_PER_PAGE,
            }}
          >
            <IncompleteFailedTable />
          </ConsignmentProvider>
        ),
      },
      {
        key: 'recentlyCompleted',
        name: 'Recently Completed',
        icon: <PIcon sx={{ marginRight: 1, marginTop: 0.5, marginBottom: 0.5 }} name="bag" />,
        indicatorStatuses: [],
        component: (
          <ConsignmentProvider
            queryParams={{
              countryCode: countryCode,
              archived: false,
              page: DEFAULT_PAGE,
              itemsPerPage: ITEM_PER_PAGE,
              status: ['complete'],
              ...filterRecentlyCompleted,
            }}
          >
            <CompletedTable />
          </ConsignmentProvider>
        ),
      },
      {
        key: 'archived',
        name: 'Archived',
        icon: <PIcon sx={{ marginRight: 1, marginTop: 0.5, marginBottom: 0.5 }} name="bag" />,
        indicatorStatuses: [],
        component: (
          <ConsignmentProvider
            queryParams={{ page: DEFAULT_PAGE, itemsPerPage: ITEM_PER_PAGE, archived: true, ...filterArchived }}
          >
            <ArchivedTable />
          </ConsignmentProvider>
        ),
      },
    ],
    [countryCode, filterArchived, orderTypeCode, filterRecentlyCompleted]
  );

  const [currentTabKey, setCurrentTabKey] = useState(tabs[0].key);
  const currentTab = tabs.find((tab) => tab.key === currentTabKey) || tabs[0];

  useEffect(() => {
    setUpdatedStatusCodes((curCodes) => {
      return curCodes.filter((c) => !currentTab.indicatorStatuses.includes(c));
    });
  }, [currentTab.indicatorStatuses, setUpdatedStatusCodes]);

  const showAddNewModal = () => {
    setCountry(countries.find((c) => c.code === countryCode));
    openDrawer('new-consignment');
  };

  const handleChange = (event: React.SyntheticEvent, newKey: string) => {
    setCurrentTabKey(newKey);
  };

  const handleFilterChange = ({ target: { value } }: SelectChangeEvent<string>) => {
    setOrderTypeCode(value);
  };

  const handleReportCsvArchive = () => {
    reportCsvArchive({ ...filterArchived }).then((response) => {
      const csvFileBlob = new Blob([response], { type: 'text/csv' });
      const blobUrl = URL.createObjectURL(csvFileBlob);
      window.open(blobUrl, '_top');
    });
  };

  const handleFilterArchivedChange = (values: {
    searchText: string;
    dataFrom: number | string;
    dataTo: number | string;
    countryCode: string;
  }) => {
    setFilterArchived(values);
  };

  const handleFilterRecentlyCompletedChange = (values: {
    dataFrom: number | string;
    dataTo: number | string;
    orderType: string;
  }) => {
    setFilterRecentlyCompleted(values);
  };

  const showIndicator = useShowIndicator();

  return (
    <Box className={classes.container}>
      <Navbar>
        <Box display="flex" alignItems="center">
          <Box flex={1}>
            {currentTabKey === 'current' ? (
              <FormControl sx={{ minWidth: 200, marginTop: 0.5, marginBottom: 0.5 }}>
                <InputLabel>Filter by Type</InputLabel>
                <Select
                  variant="filled"
                  value={orderTypeCode}
                  onChange={handleFilterChange}
                  className={classes.select}
                  disableUnderline={true}
                  name=""
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {orderTypes.map((orderType) => (
                    <MenuItem key={orderType.code} value={orderType.code}>
                      {orderType.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : null}
            {currentTabKey === 'archived' ? (
              <Formik
                validationDisabled={true}
                initialValues={filterArchived}
                onSubmit={handleFilterArchivedChange}
                enableReinitialize
              >
                {({ handleSubmit }) => {
                  const handleChange = () => {
                    handleSubmit();
                  };
                  return (
                    <>
                      <FormControl sx={{ minWidth: 200, marginRight: 1, marginTop: 0.5, marginBottom: 0.5 }}>
                        <Search
                          name="searchText"
                          placeholder="Search ID #, Driver or Company"
                          onChange={handleChange}
                          sx={{ width: '340px' }}
                        />
                      </FormControl>
                      <FormControl sx={{ width: 200, marginRight: 1, marginTop: 0.5, marginBottom: 0.5 }}>
                        <PDatepicker
                          onChange={handleChange}
                          name="dataFrom"
                          fullWidth
                          label="Date From"
                          inputFormat="dd/MM/yy"
                          mask="__/__/__"
                        />
                      </FormControl>
                      <FormControl sx={{ width: 200, marginRight: 1, marginTop: 0.5, marginBottom: 0.5 }}>
                        <PDatepicker
                          onChange={handleChange}
                          name="dataTo"
                          fullWidth
                          label="Date To"
                          inputFormat="dd/MM/yy"
                          mask="__/__/__"
                        />
                      </FormControl>
                      <FormControl sx={{ width: 200, marginRight: 1, marginTop: 0.5, marginBottom: 0.5 }}>
                        <CountrySelect name="countryCode" label="Filter by Country" onChange={handleChange} />
                      </FormControl>
                      <Button
                        sx={{ height: '60px', marginTop: 0.5, marginBottom: 0.5 }}
                        variant="outlined"
                        size="medium"
                        onClick={handleReportCsvArchive}
                      >
                        <PIcon sx={{ marginRight: 1 }} name="file" />
                        Create Report
                      </Button>
                    </>
                  );
                }}
              </Formik>
            ) : null}
            {currentTabKey === 'recentlyCompleted' ? (
              <Formik
                validationDisabled={true}
                initialValues={filterRecentlyCompleted}
                onSubmit={handleFilterRecentlyCompletedChange}
                enableReinitialize
              >
                {({ handleSubmit }) => {
                  const handleChange = () => {
                    handleSubmit();
                  };
                  return (
                    <>
                      <FormControl sx={{ width: 200, marginRight: 1, marginTop: 0.5, marginBottom: 0.5 }}>
                        <PDatepicker
                          onChange={handleChange}
                          name="dataFrom"
                          fullWidth
                          label="Date From"
                          inputFormat="dd/MM/yy"
                          mask="__/__/__"
                        />
                      </FormControl>
                      <FormControl sx={{ width: 200, marginRight: 1, marginTop: 0.5, marginBottom: 0.5 }}>
                        <PDatepicker
                          onChange={handleChange}
                          name="dataTo"
                          fullWidth
                          label="Date To"
                          inputFormat="dd/MM/yy"
                          mask="__/__/__"
                        />
                      </FormControl>
                      <FormControl sx={{ minWidth: 200, marginRight: 1, marginTop: 0.5, marginBottom: 0.5 }}>
                        <OrderTypeSelect name="orderType" label="Filter by Type" onChange={handleChange} />
                      </FormControl>
                    </>
                  );
                }}
              </Formik>
            ) : null}
          </Box>
          <DispatchViewButton />
          <Button
            variant="contained"
            size="medium"
            className={classes.darkButton}
            to={`/admin/accounts/${countryCode}`}
            component={Link}
            sx={{ marginTop: 0.5, marginBottom: 0.5 }}
          >
            <PIcon sx={{ marginRight: 1 }} name="profile" />
            View Team Accounts
          </Button>
          {['current', 'scheduled', 'incompleteFailed'].includes(currentTabKey) ? (
            <Button
              sx={{ marginTop: 0.5, marginBottom: 0.5 }}
              variant="contained"
              size="medium"
              onClick={showAddNewModal}
            >
              <PIcon sx={{ marginRight: 1 }} name="plus" />
              Add New
            </Button>
          ) : null}
        </Box>
      </Navbar>
      <Tabs value={currentTabKey} onChange={handleChange} variant="fullWidth">
        {tabs.map((tab) => (
          <Tab
            key={tab.key}
            value={tab.key}
            label={<TabLabel label={tab.name} showIndicator={showIndicator(tab.indicatorStatuses)} />}
            icon={tab.icon}
            iconPosition="start"
          />
        ))}
      </Tabs>
      <Box className={classes.tabContent}>{currentTab.component}</Box>
    </Box>
  );
};

export default function OpsDashboardWrapper() {
  const { countryCode = '' } = useParams<'countryCode'>();
  return (
    <ConsignmentProvider
      queryParams={{
        countryCode: countryCode,
        archived: false,
        page: DEFAULT_PAGE,
        itemsPerPage: ITEM_PER_PAGE,
      }}
    >
      <OpsDashboard />
    </ConsignmentProvider>
  );
}
