import React, { useContext, useRef, useState } from 'react';
import ModalContext from '../../../context/modal';
import { Box, Button, ButtonGroup, Divider, Theme } from '@mui/material';
import { TabButton } from './TabButton';
import { TabConsignment } from './TabConsignment';
import { TabNotes } from './TabNotes';
import { createStyles, makeStyles } from '@mui/styles';
import { Colors } from '../../../pegasus/Colors';
import useConsignmentActions from '../../../hooks/useConsignmentActions';
import useGetContextColor from '../../../hooks/useGetContextColor';
import useConsignmentApiRoutes from '../../../hooks/api/useConsignmentApiRoutes';
import SnackBarContext from '../../../context/snackbar';
import ViewCheckInSheet from './ViewCheckInSheet';

export type View = { label: string; value: string };
const ViewConsignmentModal = () => {
  const { consignment, openDrawer } = useContext(ModalContext);
  const [sendingEmail, setSendingEmail] = useState(false);
  const { showSnack } = useContext(SnackBarContext);
  const { emailCustomerReport } = useConsignmentApiRoutes();
  const contextColor = useGetContextColor(consignment);
  const { cancelConsignment, moveToUnAssigned, moveToInTransit, moveToAtDepot } = useConsignmentActions();
  const classes = useStyles();
  const isAssigned = consignment?.status === 'assigned' || consignment?.status === 'assignedLeg2';

  const views = useRef<View[]>([
    { label: 'Consignment', value: 'consignment' },
    { label: 'Notes', value: 'notes' },
  ]).current;

  const [view, setView] = useState(views[0]);

  const editConsignment = () => {
    openDrawer('edit-consignment');
  };
  const cancelDriver = () => {
    if (consignment) {
      cancelConsignment(consignment);
    }
  };
  const unAssign = () => {
    if (consignment) {
      moveToUnAssigned(consignment);
    }
  };

  const handleInTransitClick = () => {
    if (consignment) {
      moveToInTransit(consignment);
    }
  };
  const handleAtDepotClick = () => {
    if (consignment) {
      moveToAtDepot(consignment);
    }
  };
  const handleEmailCustomerReport = async () => {
    if (consignment) {
      try {
        setSendingEmail(true);
        await emailCustomerReport(consignment._id);
        showSnack('Email Sent', 'success');
        setSendingEmail(false);
      } catch (error) {
        setSendingEmail(false);
        console.log('error: ', error);
      }
    }
  };

  const handleDuplicate = () => {
    openDrawer('duplicate-consignment');
  };

  return consignment ? (
    <Box display="flex" flexDirection="column" flex={1}>
      <Box padding={6.5} flex={1} overflow="auto">
        <Box display="flex" flexDirection="column" alignItems="center" mb={6}>
          <ButtonGroup>
            {views.map((_view) => (
              <TabButton
                contextColor={contextColor || ''}
                key={_view.value}
                view={_view}
                setView={setView}
                active={_view === view}
              />
            ))}
          </ButtonGroup>
        </Box>
        <Box>
          {view.value === 'consignment' ? <TabConsignment contextColor={contextColor || ''} /> : null}
          {view.value === 'notes' ? <TabNotes contextColor={contextColor || ''} /> : null}
        </Box>
      </Box>

      <Divider />

      <Box display="flex" paddingX={6.5} paddingBottom={5} paddingTop={3} color={contextColor}>
        {consignment.status === 'onHold' ? (
          <Button variant="outlined" className={classes.button} color="inherit" onClick={unAssign}>
            Move To Un-Assigned
          </Button>
        ) : null}
        <Button
          variant="contained"
          className={classes.button}
          sx={{ backgroundColor: contextColor }}
          onClick={editConsignment}
        >
          Edit Consignment
        </Button>
        {isAssigned ? (
          <>
            <Button variant="contained" className={classes.button} onClick={cancelDriver}>
              Cancel Driver
            </Button>
            {consignment.orderType === 'drop-off' || consignment.orderType === 'transfer' ? (
              <Button variant="outlined" className={classes.button} color="inherit" onClick={handleInTransitClick}>
                In Transit
              </Button>
            ) : null}
          </>
        ) : null}
        {consignment.orderType === 'transfer' && consignment.status === 'inTransit' ? (
          <Button variant="outlined" className={classes.button} color="inherit" onClick={handleAtDepotClick}>
            At Depot
          </Button>
        ) : null}
        <Button variant="outlined" className={classes.button} color="inherit" onClick={handleDuplicate}>
          Create New Duplicate
        </Button>
        {consignment.proofOfDelivery ? (
          <Button
            variant="contained"
            onClick={handleEmailCustomerReport}
            className={`${classes.button} ${classes.greyButton}`}
            disabled={sendingEmail}
          >
            Email Customer Report
          </Button>
        ) : null}
        <ViewCheckInSheet />
      </Box>
    </Box>
  ) : null;
};

export default ViewConsignmentModal;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginRight: theme.spacing(1.5),
    },
    greyButton: {
      backgroundColor: Colors.greyscale.offBlack,
      opacity: 0.5,
    },
  })
);
