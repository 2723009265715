import { Colors } from '../pegasus/Colors';

export type OrderType = {
  name: string;
  code: string;
  contextColor: string;
  backgroundColor: string;
};

const orderTypes: OrderType[] = [
  {
    name: 'Pick Up',
    code: 'pick-up',
    contextColor: Colors.primary.dark,
    backgroundColor: Colors.primary.lightBackground,
  },
  {
    name: 'Drop Off',
    code: 'drop-off',
    contextColor: Colors.secondary.dark,
    backgroundColor: Colors.secondary.background,
  },
  { name: 'Transfer', code: 'transfer', contextColor: Colors.success.main, backgroundColor: Colors.success.background },
];
export default orderTypes;
