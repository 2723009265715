import React, { useContext } from 'react';
import { Grid, LinearProgress, Typography } from '@mui/material';
import { TableField } from '../../../../types';
import { Colors } from '../../../pegasus/Colors';
import { createStyles, makeStyles } from '@mui/styles';
import ConsignmentContext from '../../../context/consignments';

const styles = makeStyles(() =>
  createStyles({
    cell: {
      paddingLeft: 18,
      paddingRight: 16,
    },
  })
);

const TableHeader = ({ tableFields, columnCount }: { tableFields: TableField[]; columnCount: number }) => {
  const classes = styles();
  const { isLoading } = useContext(ConsignmentContext);
  return (
    <>
      <Grid container columns={columnCount} marginBottom={2}>
        {tableFields.map((field, index) => (
          <Grid key={index} item xs={field.columns} className={classes.cell}>
            <Typography variant="textXSmall" color={Colors.greyscale.label} fontWeight={500}>
              {field.label}
            </Typography>
          </Grid>
        ))}
      </Grid>
      {isLoading ? <LinearProgress /> : null}
    </>
  );
};
export default TableHeader;
