import React, { useRef } from 'react';
import { Box, IconButton, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { PIcon } from '../pegasus/PIcon';
import { Image } from '../../types';
import { Colors } from '../pegasus/Colors';

const imageWidth = 150;
const margin = 10;

const styles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
    },
    scrollContainer: {
      flex: 1,
      overflow: 'hidden',
      display: 'flex',
      scrollBehavior: 'smooth',
      marginLeft: margin,
    },
    image: { maxWidth: imageWidth, borderRadius: theme.spacing(1), marginRight: margin },
  })
);

export default function Carousel({ photos }: { photos?: Image[] }) {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const classes = styles();
  const goForward = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft += imageWidth + margin;
    }
  };
  const goBack = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft -= imageWidth + margin;
    }
  };

  return photos && photos.length ? (
    <Box className={classes.container}>
      <IconButton onClick={goBack}>
        <PIcon name="chevronBack" sx={{ color: Colors.greyscale.placehold }} />
      </IconButton>
      <Box className={classes.scrollContainer} ref={scrollContainerRef}>
        {photos.map((photo) => {
          return photo.signedUrl ? (
            <img key={photo.uid} className={classes.image} src={photo.signedUrl} alt={photo.filename} />
          ) : null;
        })}
      </Box>
      <IconButton onClick={goForward}>
        <PIcon name="chevronForward" sx={{ color: Colors.greyscale.placehold }} />
      </IconButton>
    </Box>
  ) : null;
}
