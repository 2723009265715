import { Box, Drawer, DrawerProps, IconButton, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Colors } from '../../pegasus/Colors';
import ModalContext from '../../context/modal';
import { DrawerType } from '../../../types';
import { getModalType, largeModalTypes } from '../../constants/drawerTypes';

type Props = DrawerProps & {
  drawerType: DrawerType;
  headerText?: string | React.ReactNode;
  contextColor?: string;
  contentPadding?: boolean;
};

const largeModalBasePercent = 85;
const largeModalMinBasePercent = 45;

const AppDrawer = ({ open, onClose, drawerType, children, headerText, contextColor, contentPadding = true }: Props) => {
  const theme = useTheme();
  const classes = useStyles();
  const modalLogoMap = useRef({
    large: { textColor: theme.palette.common.white },
    small: { textColor: theme.palette.common.white },
  }).current;
  const { openDrawers, closeDrawer } = useContext(ModalContext);
  const modalType = getModalType(drawerType);
  const [paperWidth, setPaperWidth] = useState<string | undefined>();

  useEffect(() => {
    if (open) {
      const largeOpenDrawers = openDrawers.filter((openDrawer) => largeModalTypes.includes(openDrawer));
      const indexOffset = modalType === 'small' ? 0 : largeOpenDrawers.findIndex((d) => d === drawerType);
      const overridePercent = indexOffset >= 0 ? largeModalBasePercent - 10 * indexOffset : 0;
      const overrideWidth = `${Math.max(overridePercent, largeModalMinBasePercent)}%`;
      setPaperWidth(overrideWidth);
    }
  }, [drawerType, modalType, open, openDrawers]);

  const { textColor } = modalLogoMap[modalType];
  const handleClose = () => {
    closeDrawer(drawerType);
    if (onClose) {
      onClose({}, 'escapeKeyDown');
    }
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      classes={{ paper: classes[modalType] }}
      PaperProps={{
        sx: { width: paperWidth },
      }}
    >
      <Box className={classes.container}>
        <Box
          color={textColor}
          className={classes.header}
          sx={{ backgroundColor: contextColor ? contextColor : Colors.primary.main }}
          display="flex"
          justifyContent="space-between"
          position="relative"
        >
          {typeof headerText === 'string' ? (
            <Typography variant="displayXSmallBold" marginY={3}>
              {headerText}
            </Typography>
          ) : (
            headerText
          )}
          <IconButton size="medium" onClick={handleClose}>
            <CloseIcon fontSize="large" style={{ color: textColor }} />
          </IconButton>
        </Box>
        <Box
          className={classes.contentContainer}
          padding={contentPadding ? 6.5 : 0}
          paddingBottom={contentPadding ? 4 : 0}
        >
          {children}
        </Box>
      </Box>
    </Drawer>
  );
};

export default AppDrawer;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      maxHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    header: {
      alignItems: 'center',
      paddingLeft: theme.spacing(6.5),
      borderRadius: '0px',
    },
    contentContainer: {
      flex: 1,
      display: 'flex',
      overflow: 'auto',
    },
    large: {
      backgroundColor: Colors.greyscale.offWhite,
      borderRadius: '0px',
    },
    small: {
      backgroundColor: theme.palette.grey[100],
      width: '30%',
      borderRadius: '0px',
      minWidth: theme.spacing(54.5),
    },
  })
);
