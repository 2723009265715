import { useContext, useMemo } from 'react';
import debounce from 'lodash/debounce';
import GoogleApiContext, { componentRestrictions, PlaceType } from '../context/googleApi';
const debounceDelay = 500;

export const useGetGooglePredictions = () => {
  const { autocompleteService } = useContext(GoogleApiContext);
  const getPredictions = useMemo(
    () =>
      debounce(async (request: { input: string }, callback: (results?: readonly PlaceType[]) => void) => {
        const { predictions } = autocompleteService
          ? await autocompleteService.getPlacePredictions({ ...request, componentRestrictions })
          : { predictions: [] };
        return callback(predictions);
      }, debounceDelay),
    [autocompleteService]
  );
  return getPredictions;
};
