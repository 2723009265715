// import { Autocomplete } from '@react-google-maps/api';
import { Autocomplete } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { useContext, useEffect, useRef } from 'react';
import { PTextField } from '../../pegasus/PTextField';
import { PlaceType } from '../../context/googleApi';
import get from 'lodash/get';
import parse from 'autosuggest-highlight/parse';
import { PropsOf } from '@emotion/react';
import { useGetGooglePredictions } from '../../hooks/useGetGooglePredictions';
import { AutoCompleteOption } from './AddressSelect';
import GoogleApiContext from '../../context/googleApi';

export default function AutoCompleteAddress({
  name,
  label,
  ...otherProps
}: Omit<PropsOf<typeof Autocomplete>, 'renderInput' | 'options' | 'onChange'> & {
  name: string;
  label: string;
}) {
  const [inputValue, setInputValue] = React.useState('');
  const { values, handleChange } = useFormikContext();
  const formValue = get(values, name, '');
  const defaultValue: PlaceType = useRef({
    description: formValue,
    structured_formatting: {
      main_text: formValue,
      secondary_text: '',
      main_text_matched_substrings: [],
    },
  }).current;
  const { placesService } = useContext(GoogleApiContext);
  const [value, setValue] = React.useState<PlaceType | null>(defaultValue);
  const [options, setOptions] = React.useState<readonly PlaceType[]>([]);
  const getPredictions = useGetGooglePredictions();

  useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    getPredictions({ input: inputValue }, (results) => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, getPredictions]);

  const handleAutoCompleteChange = (event: React.SyntheticEvent<Element, Event>, newValue: PlaceType | null) => {
    setOptions(newValue ? [newValue, ...options] : options);
    setValue(newValue);
    handleChange({ target: { name: name, value: newValue?.description } });
    if (newValue?.place_id && placesService) {
      placesService.getDetails(
        { placeId: newValue?.place_id, fields: ['geometry.location', 'address_components'] },
        (placeResult) => {
          const country = placeResult?.address_components?.filter((aComp) =>
            aComp.types.some((typesItem) => typesItem === 'country')
          );
          if (country && country.length > 0) {
            handleChange({ target: { name: 'originCountryCode', value: country[0].short_name } });
          }
          const state = placeResult?.address_components?.filter((aComp) =>
            aComp.types.some((typesItem) => typesItem === 'administrative_area_level_1')
          );
          if (state && state.length > 0) {
            handleChange({ target: { name: 'state', value: state[0].long_name } });
          }
          handleChange({
            target: {
              name: 'location',
              value: {
                lat: placeResult?.geometry?.location?.lat(),
                lng: placeResult?.geometry?.location?.lng(),
              },
            },
          });
        }
      );
    }
  };

  return (
    <Autocomplete
      fullWidth
      getOptionLabel={(option: unknown) => (typeof option === 'string' ? option : (option as PlaceType).description)}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={handleAutoCompleteChange as () => void}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={({ inputProps, InputProps, ...otherParams }) => (
        <PTextField
          {...otherParams}
          inputProps={{ ...inputProps, autoComplete: 'new-password' }}
          InputProps={{ disableUnderline: true, ...InputProps }}
          name={name}
          label={label}
          fullWidth
        />
      )}
      renderOption={(props, option) => {
        const placeType = option as PlaceType;
        const matches = placeType.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          placeType.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length])
        );

        return (
          <AutoCompleteOption
            key={props.id}
            listElemProps={props}
            parts={parts}
            secondaryText={(option as PlaceType).structured_formatting.secondary_text}
          />
        );
      }}
      {...otherProps}
    />
  );
}
