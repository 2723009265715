import React from 'react';
import { Consignment } from '../../../../types';
import { createStyles, makeStyles } from '@mui/styles';
import { Box, Theme, Typography } from '@mui/material';
import { Colors } from '../../../pegasus/Colors';
import Carousel from '../../../components/Carousel';

const styles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2),
      borderRadius: theme.spacing(1.5),
      backgroundColor: Colors.primary.background,
      marginBottom: theme.spacing(2),
    },
    image: {
      width: '100%',
      maxHeight: 300,
      objectFit: 'contain',
    },
  })
);

function ProofOfDelivery({ consignment }: { consignment: Consignment }) {
  const classes = styles();
  return consignment.proofOfDelivery ? (
    <>
      <Box className={classes.container}>
        <Typography variant="textSmall">Signed by: {consignment.proofOfDelivery.customerName}</Typography>
        <img
          className={classes.image}
          src={consignment.proofOfDelivery.customerSignature?.signedUrl}
          alt={consignment.proofOfDelivery.customerSignature?.filename}
        />
        <Typography variant="textSmall">Customer Rating: {consignment.proofOfDelivery.rating}</Typography>
        <Typography variant="textSmall">Delivery Notes: {consignment.proofOfDelivery.deliveryNotes}</Typography>
      </Box>
      <Box mb={2}>
        <Carousel photos={consignment.proofOfDelivery.photos} />
      </Box>
    </>
  ) : null;
}

export default ProofOfDelivery;
