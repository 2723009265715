import { Box, MenuItem } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useContext, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { PTextField } from '../../../pegasus/PTextField';
import { PSelect } from '../../../pegasus/PSelect';
import { Depot, User } from '../../../../types';
import AppDrawer from '../../../components/Modals/AppDrawer';
import NewDepotModal from '../depot/NewDepotModal';
import DepotSelect from '../../../components/form/DepotSelect';
import countries from '../../../constants/countries';
import { PhoneNumberInput } from '../../../pegasus/PPhoneNumberInput';
import UserContext from '../../../context/user';

type Props = {
  initialValues: Partial<User>;
  onSubmit: (values: Partial<User>, formikHelpers: FormikHelpers<Partial<User>>) => void | Promise<User>;
  children: React.ReactNode;
};

const UserForm = ({ initialValues, onSubmit, children }: Props) => {
  const { user } = useContext(UserContext);
  const isNewDriver = initialValues.type === 'driver';
  const disableType = isNewDriver || !!initialValues._id;
  const classes = useStyles();
  const [depotModalOpen, setDepotModalOpen] = useState(false);
  const validate = (values: Partial<User>) => {
    // eslint-disable-next-line no-unused-vars
    const errors: Partial<{ [key in keyof User]: string }> = {};
    (Object.keys(initialValues) as Array<keyof typeof initialValues>).forEach((k) => {
      if (
        !values[k] &&
        values[k] !== 0 &&
        values[k] !== false &&
        !(k === 'email' && values.type === 'driver') && // email not required for driver
        !(k === 'phone' && values.type !== 'driver') // phone optional for non-driver
      ) {
        errors[k] = 'Required';
      }
    });
    return errors;
  };

  const handleAddDepot = () => {
    setDepotModalOpen(true);
  };
  const closeNewDepotModal = () => {
    setDepotModalOpen(false);
  };
  return (
    <Formik initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
      {({ handleSubmit, values, setFieldValue }) => {
        const handleDepotCreate = (depot: Depot) => {
          closeNewDepotModal();
          setFieldValue('depots', [...(values.depotIds || []), depot._id]);
        };

        return (
          <>
            <form onSubmit={handleSubmit} className={classes.form}>
              <PSelect name="type" fullWidth label="User Type" disabled={disableType}>
                <MenuItem value="operations">Operations</MenuItem>
                <MenuItem value="dispatch">Dispatch</MenuItem>
                <MenuItem value="warehouse-manager">Warehouse Manager</MenuItem>
                <MenuItem value="branch-manager" disabled={user?.type !== 'admin'}>
                  Branch Manager
                </MenuItem>
                <MenuItem value="driver">Driver</MenuItem>
              </PSelect>
              <PTextField name="firstName" fullWidth label="First Name" />
              <PTextField name="lastName" fullWidth label="Last Name" />
              {values.type === 'driver' ? null : <PTextField name="email" fullWidth label="Email" type="email" />}
              <PhoneNumberInput name="phone" fullWidth label="Mobile #" />

              {isNewDriver ? null : (
                <PSelect name="countryCode" fullWidth label="Country" disabled={user?.type !== 'admin'}>
                  {countries.map((country) => (
                    <MenuItem key={country.code} value={country.code}>
                      {country.name}
                    </MenuItem>
                  ))}
                </PSelect>
              )}
              {/* hide when adding a depot to esure depots are reloaded */}
              {depotModalOpen ? null : (
                <DepotSelect
                  countryCode={values.countryCode || ''}
                  name="depots"
                  label="Depots"
                  multiple={true}
                  onAddDepot={handleAddDepot}
                  setFieldValue={setFieldValue}
                  value={values.depots as string[]}
                />
              )}
              <Box marginTop={4}>{children}</Box>
            </form>
            <AppDrawer
              drawerType="new-depot"
              headerText="Add New Depot"
              open={depotModalOpen}
              onClose={closeNewDepotModal}
            >
              <NewDepotModal
                onDepotCreate={handleDepotCreate}
                initialValues={{
                  companyName: '',
                  address: '',
                  contactName: '',
                  phone: '',
                  email: '',
                  countryCode: values.countryCode || '',
                }}
              />
            </AppDrawer>
          </>
        );
      }}
    </Formik>
  );
};

export default UserForm;

const useStyles = makeStyles(() =>
  createStyles({
    form: {
      flex: 1,
    },
  })
);
