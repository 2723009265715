import React, { useContext } from 'react';
import { Consignment, Depot, User } from '../../../../types';
import { createStyles, makeStyles } from '@mui/styles';
import { Box, Button, Theme, Typography } from '@mui/material';
import { Colors } from '../../../pegasus/Colors';
import { format } from 'date-fns';
import { StatusMarker } from './StatusMarker';
import ModalContext from '../../../context/modal';
import { ConsignmentBadges } from './ConsignmentBadges';
import useFilterDriverConsignments from '../../../hooks/useFilterDriverConsignments';
import useGetOrderType from '../../../hooks/useGetOrderType';
import useGetContextColor from '../../../hooks/useGetContextColor';

export const DriverConsignments = ({ selectedDriver }: { selectedDriver?: User }) => {
  const thisDriverConsignments = useFilterDriverConsignments(selectedDriver);
  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Box display="flex" flexDirection="row" justifyContent="space-between" mb={1.5} px={2}>
        <Typography variant="textXSmall" color={Colors.greyscale.label} fontWeight={500}>
          LOCATION
        </Typography>
        <Typography variant="textXSmall" color={Colors.greyscale.label} fontWeight={500}>
          STATUS
        </Typography>
      </Box>
      <Box flex={1} overflow="auto">
        {thisDriverConsignments.map((consignment, index) => (
          <DriverConsignmentCard index={index} consignment={consignment} key={consignment._id} />
        ))}
      </Box>
    </Box>
  );
};

const borderRadius = 6;
const styles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: Colors.greyscale.offWhite,
      borderTopRightRadius: borderRadius,
      borderBottomRightRadius: borderRadius,
      borderBottomLeftRadius: borderRadius,
      flex: 1,
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    link: {
      textDecoration: 'underline',
      marginRight: theme.spacing(1),
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
    },
    greyBox: {
      backgroundColor: Colors.primary.background,
      padding: theme.spacing(2),
      borderRadius: borderRadius,
    },
    rightText: {
      textAlign: 'right',
      fontWeight: 500,
      fontSize: 13,
      lineHeight: '18px',
      letterSpacing: 0.25,
      fontStyle: 'italic',
    },
  })
);

const DriverConsignmentCard = ({ consignment, index }: { consignment: Consignment; index: number }) => {
  const classes = styles();
  const { openDrawer, setConsignment } = useContext(ModalContext);
  const getOrderType = useGetOrderType();
  const orderType = getOrderType(consignment);
  const contextColor = useGetContextColor(consignment);

  const viewConsignment = () => {
    setConsignment(consignment);
    openDrawer('view-consignment');
  };

  return (
    <Box
      key={consignment._id}
      className={classes.container}
      sx={{ borderTopLeftRadius: index === 0 ? 0 : borderRadius }}
    >
      <Box display="flex" flexWrap="wrap" mb={2}>
        <Button onClick={viewConsignment} size="small">
          <Typography variant="linkSmall" className={classes.link} sx={{ color: contextColor }}>
            #{consignment.consignmentId}
          </Typography>
        </Button>
        <ConsignmentBadges consignment={consignment} backgroundColor={orderType?.backgroundColor} />
      </Box>

      <Box className={`${classes.greyBox} ${classes.row}`}>
        <Box flex={1} className={classes.row}>
          <Box mr={2}>
            <StatusMarker consignment={consignment} />
          </Box>
          <Box>
            <Typography variant="textXSmall" fontWeight={600}>
              {consignment.sender.companyName}
            </Typography>
            <br />
            <Typography variant="textXSmall">{consignment.sender.address}</Typography>
            <br />
            <br />
            {consignment.orderType === 'transfer' ? (
              <>
                <Typography variant="textXSmall" fontWeight={600}>
                  {(consignment.depot as Depot).companyName}
                </Typography>
                <br />
                <Typography variant="textXSmall">{(consignment.depot as Depot).address}</Typography>
                <br />
                <br />
              </>
            ) : null}
            <Typography variant="textXSmall" fontWeight={600}>
              {consignment.receiver.companyName}
            </Typography>
            <br />
            <Typography variant="textXSmall">{consignment.receiver.address}</Typography>
          </Box>
        </Box>
        <Box flex={1}>
          <DateUpdate
            label="Assigned"
            date={consignment.assignedAt}
            color={consignment.pickedUpDate ? Colors.greyscale.coolGrey : Colors.greyscale.coolGrey600}
          />
          <DateUpdate label="Collected" date={consignment.pickedUpDate} color={Colors.greyscale.coolGrey600} />
        </Box>
      </Box>
    </Box>
  );
};

const DateUpdate = ({ label, date, color }: { label: string; date: number | undefined; color: string }) => {
  const classes = styles();
  return date ? (
    <Box mb={1}>
      <Typography className={classes.rightText} color={color}>
        {label}
      </Typography>
      <Typography className={classes.rightText} color={color}>
        {format(new Date(date), 'd MMM, h:mm aa')}
      </Typography>
    </Box>
  ) : null;
};
