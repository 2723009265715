import { CalculatedBy, Consignment, LineItem } from '../../types';
const toM3 = (cubicCm: number) => cubicCm / (100 * 100 * 100);
export default function useGetTotalCube() {
  return (
    dimensions: Pick<LineItem, 'height' | 'width' | 'length' | 'units' | 'totalCube'>,
    calculateVolumeBy?: CalculatedBy
  ) =>
    calculateVolumeBy === 'totalShipment'
      ? dimensions.totalCube
      : toM3(dimensions.height * dimensions.width * dimensions.length);
}

export function useGetRowTotals(consignment?: Consignment) {
  const getTotalCube = useGetTotalCube();
  let unitTotal = 0;
  let weightTotal = 0;
  let cubeTotal = 0;
  consignment?.lineItems?.forEach((lineItem) => {
    unitTotal += lineItem.units;
    const multiplier = consignment.calculateVolumeBy === 'unitType' ? lineItem.units : 1;
    weightTotal += lineItem.weight * multiplier;
    cubeTotal += getTotalCube(lineItem, consignment.calculateVolumeBy) * multiplier;
  });
  return {
    unitTotal,
    weightTotal,
    cubeTotal,
  };
}
