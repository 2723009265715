import { Box, Button, ButtonProps, CircularProgress } from '@mui/material';
import { useFormikContext } from 'formik';
import React from 'react';

export const SubmitButton = (props: ButtonProps) => {
  const { children, ...otherProps } = props;
  const { dirty, isValid, isSubmitting } = useFormikContext();

  return (
    <Button
      variant="contained"
      color="primary"
      type="submit"
      disabled={!dirty || !isValid || isSubmitting}
      {...otherProps}
    >
      {isSubmitting ? (
        <Box mr={1} display="flex">
          <CircularProgress size={24} color="inherit" />
        </Box>
      ) : null}
      {children}
    </Button>
  );
};
