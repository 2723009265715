import { Box, Button, IconButton, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import Navbar from '../../components/NavBar';
import { Link, useParams } from 'react-router-dom';
import { PIcon } from '../../pegasus/PIcon';
import { Colors } from '../../pegasus/Colors';
import DriversTable from './tables/DriversTable';
import AppDrawer from '../../components/Modals/AppDrawer';
import NewDriverModal from './components/NewDriverModal';
import DepotContext from '../../context/depot';

const styles = makeStyles(() =>
  createStyles({
    container: {
      backgroundColor: Colors.primary.background,
      flex: 1,
    },
    addButton: {
      backgroundColor: Colors.primary.main,
      color: Colors.primary.background,
    },
  })
);

const OurDrivers = () => {
  const classes = styles();
  const { countryCode = '' } = useParams<'countryCode'>();
  const { depot } = useContext(DepotContext);
  const [open, setOpen] = useState(false);

  const handleAddDriver = () => {
    setOpen(true);
  };
  const handleOnClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box className={classes.container}>
        <Navbar>
          <Box display="flex" alignItems="center">
            <Box flex={1} display="flex">
              <Typography flex={1} variant="linkLarge" color={Colors.greyscale.offBlack}>
                {depot?.companyName}
              </Typography>
            </Box>
            <Button
              variant="contained"
              size="medium"
              to={`/dispatch/${countryCode}/${depot?._id}/dashboard`}
              component={Link}
            >
              <PIcon name="backArrow" sx={{ marginRight: 1 }} />
              Back To Consignments
            </Button>
          </Box>
        </Navbar>
        <Box display="flex" flex={1} justifyContent="space-between" m={3} mr={1.5}>
          <Typography variant="linkLarge" color="primary">
            Our Drivers
          </Typography>
          <IconButton className={classes.addButton} onClick={handleAddDriver}>
            <PIcon name="plus" />
          </IconButton>
        </Box>
        <Box m={1.5}>
          <DriversTable />
        </Box>
      </Box>
      {/* Note: must put the NewDriverModal inside the Depo context for this to work */}
      <AppDrawer drawerType="new-driver" headerText="Invite New Driver" open={open} onClose={handleOnClose}>
        <NewDriverModal setOpen={setOpen} />
      </AppDrawer>
    </>
  );
};

export default OurDrivers;
