import React, { useContext, useState } from 'react';
import { Button, Typography, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import ModalContext from '../../../context/modal';
import { PIcon } from '../../../pegasus/PIcon';
import { Consignment } from '../../../../types';
import useConsignmentApiRoutes from '../../../hooks/api/useConsignmentApiRoutes';
import ViewPdfModal from './ViewPdfModal';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonRoot: {
      padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
      marginBottom: theme.spacing(2),
    },
  })
);

const ViewConsignmentLabel = () => {
  const [open, setOpen] = useState(false);
  const { consignment } = useContext(ModalContext);
  const { getPdfLabelA4V1, getPdfBulkNote } = useConsignmentApiRoutes();
  const [totalLabel, setTotalLabel] = useState<number | undefined>(undefined);

  const classes = useStyles();
  const showModal = () => {
    setTotalLabel(undefined);
    setOpen(true);
  };

  const showModalWithSingleLabel = () => {
    setTotalLabel(1);
    setOpen(true);
  };

  return consignment ? (
    <ViewPdfModal
      totalLabel={totalLabel}
      open={open}
      setOpen={setOpen}
      getPdfDocument={totalLabel ? getPdfBulkNote : getPdfLabelA4V1}
      printButtonText={totalLabel ? 'Print Bulk Consignment Note' : 'Print Label/s'}
    >
      <Button
        sx={{ marginRight: 1 }}
        variant="outlined"
        color="inherit"
        className={classes.buttonRoot}
        onClick={showModalWithSingleLabel}
      >
        <PIcon name="ticket" />
        <Typography ml={1.5} variant="linkSmall">
          View Bulk Consignment Note
        </Typography>
      </Button>
      <Button variant="outlined" color="inherit" className={classes.buttonRoot} onClick={showModal}>
        <PIcon name="ticket" />
        <Typography ml={1.5} variant="linkSmall">
          View Consignment Label/s
        </Typography>
      </Button>
    </ViewPdfModal>
  ) : null;
};

export interface ConsignmentLabelDialogProps {
  open: boolean;
  onClose: () => void;
  consignment: Consignment;
}

export default ViewConsignmentLabel;
