import { Colors } from '../pegasus/Colors';

export default [
  { name: 'Assigned', code: 'assigned', contextColor: Colors.secondary.main, showOnConsignment: false },
  { name: 'In Transit', code: 'inTransit', contextColor: Colors.success.main, showOnConsignment: false },
  { name: 'At Depot', code: 'atDepot', contextColor: '#03CFBB', showOnConsignment: false },
  {
    name: 'Assigned (Second Leg)',
    code: 'assignedLeg2',
    contextColor: Colors.secondary.main,
    showOnConsignment: false,
  },
  {
    name: 'In Transit (Second Leg)',
    code: 'inTransitLeg2',
    contextColor: Colors.success.main,
    showOnConsignment: false,
  },
  { name: 'On Hold', code: 'onHold', contextColor: Colors.primary.light, showOnConsignment: true },
  { name: 'Unassigned', code: 'unassigned', contextColor: Colors.error.darkMode, showOnConsignment: true },
  { name: 'Failed', code: 'failed', contextColor: Colors.error.main, showOnConsignment: false },
  { name: 'Complete', code: 'complete', contextColor: Colors.primary.dark, showOnConsignment: false },
  { name: 'In Complete', code: 'inComplete', contextColor: Colors.error.main, showOnConsignment: false },
];
