import React from 'react';
import { Typography } from '@mui/material';
import { Colors } from '../../../pegasus/Colors';

export type ReadOnlyFieldProps = { label: string; value?: string; textColor?: string };
export const ReadOnlyField = ({ label, value, textColor = Colors.greyscale.offBlack }: ReadOnlyFieldProps) => {
  return (
    <>
      <Typography variant="textXSmall" color={Colors.greyscale.label} fontWeight={500}>
        {label}
      </Typography>
      {value ? (
        <>
          <br />
          <Typography variant="textSmall" color={textColor} fontWeight={400}>
            {value}
          </Typography>
        </>
      ) : null}
    </>
  );
};
