import { useCallback } from 'react';
import { ConsignmentFormValues } from '../pages/Operations/consignment/ConsignmentForm';

export interface EmailValues {
  email: string;
}

export default function useValidators() {
  const isEmailValid = useCallback((email: string) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email), []);

  const validateEmail = useCallback(
    (values: EmailValues) => {
      const errors: Partial<EmailValues> = {};
      if (!values.email) {
        errors.email = 'Required';
      } else if (!isEmailValid(values.email)) {
        errors.email = 'Invalid email address';
      }
      return errors;
    },
    [isEmailValid]
  );

  const validateConsignment = (values: ConsignmentFormValues) => {
    const errors: Partial<
      {
        'sender.address': string;
        'receiver.address': string;
      // eslint-disable-next-line no-unused-vars
      } & { [key in keyof ConsignmentFormValues]: string }
    > = {};
    if (!values.sender?.location) {
      errors['sender.address'] = 'Please select address from drop down';
    }
    if (!values.receiver?.location) {
      errors['receiver.address'] = 'Please select address from drop down';
    }
    (['consignmentId', 'depot', 'orderType'] as (keyof ConsignmentFormValues)[]).forEach((k) => {
      if (!values[k]) {
        errors[k] = 'Required';
      }
    });

    if (!(values.lineItems && values.lineItems.length >= 1)) {
      errors.lineItems = 'At least one row is required';
    }
    return errors;
  };

  return { isEmailValid, validateEmail, validateConsignment };
}
