import { useContext } from 'react';
import UserContext from '../context/user';

export default function useGetUserRoutePermissions() {
  const { user } = useContext(UserContext);
  const canSeeOperationsDashboard =
    user?.type === 'admin' || user?.type === 'operations' || user?.type === 'branch-manager';
  const canEditAddressBook = user?.type === 'admin' || user?.type === 'operations' || user?.type === 'branch-manager';
  return { canSeeOperationsDashboard, canEditAddressBook };
}
