import React, { useContext, useEffect, useState } from 'react';
import { User } from '../../../../types';
import { Grid } from '@mui/material';
import { DriverSelector } from './DriverSelector';
import { DriverConsignments } from './DriverConsignments';
import DepotContext from '../../../context/depot';

const AssignedTab = () => {
  const [selectedDriver, setSelectedDriver] = useState<User>();
  const { drivers } = useContext(DepotContext);

  useEffect(() => {
    if (drivers.length > 0) {
      setSelectedDriver((_driver) => {
        return _driver ? drivers.find((d) => d._id === _driver._id) || drivers[0] : drivers[0];
      });
    }
  }, [drivers]);

  return (
    <>
      <Grid container columns={10} height="100%">
        <Grid item xs={3} pt={4.25} height="100%" overflow="auto">
          <DriverSelector selectedDriver={selectedDriver} drivers={drivers} onDriverClick={setSelectedDriver} />
        </Grid>
        <Grid item xs={7} height="100%">
          <DriverConsignments selectedDriver={selectedDriver} />
        </Grid>
      </Grid>
    </>
  );
};

export default AssignedTab;
