import { useCallback } from 'react';
import { CheckInSheet, Consignment, StatusCode } from '../../../types';
import { REACT_APP_API_BASE_URL } from '../../env';
import { usePublicRoute } from './usePublicRoute';
import { serialize } from 'object-to-formdata';

export type ConsignmentQueryParams = {
  countryCode: string;
  orderType?: string;
  status?: string[];
  driverId?: string;
  depot?: string;
  archived?: boolean;
  searchText?: string;
  dataFrom?: number | string;
  dataTo?: number | string;
  page?: number;
  itemsPerPage?: number;
  unlimit?: boolean;
};

export default function useConsignmentApiRoutes() {
  const publicRoute = usePublicRoute();

  const create = useCallback(
    async (consignment: Partial<Consignment>): Promise<Consignment> => {
      const response = await publicRoute({
        method: 'post',
        url: `${REACT_APP_API_BASE_URL}/consignments`,
        data: consignment,
      });
      return response;
    },
    [publicRoute]
  );

  const update = useCallback(
    async (consignment: Consignment): Promise<Consignment> => {
      const response = await publicRoute({
        method: 'put',
        url: `${REACT_APP_API_BASE_URL}/consignments/${consignment._id}`,
        data: consignment,
      });
      return response;
    },
    [publicRoute]
  );

  const assignDriver = useCallback(
    async (consignment: Partial<Consignment>): Promise<Consignment> => {
      const response = await publicRoute({
        method: 'put',
        url: `${REACT_APP_API_BASE_URL}/consignments/${consignment._id}/assign-driver`,
        data: consignment,
      });
      return response;
    },
    [publicRoute]
  );

  const cancelAssignment = useCallback(
    async (consignment: Partial<Consignment>): Promise<Consignment> => {
      const response = await publicRoute({
        method: 'put',
        url: `${REACT_APP_API_BASE_URL}/consignments/${consignment._id}/cancel-assignment`,
        data: {},
      });
      return response;
    },
    [publicRoute]
  );

  const moveToUnAssigned = useCallback(
    async (consignment: Partial<Consignment>): Promise<Consignment> => {
      const response = await publicRoute({
        method: 'put',
        url: `${REACT_APP_API_BASE_URL}/consignments/${consignment._id}/move-to-unassigned`,
        data: {},
      });
      return response;
    },
    [publicRoute]
  );

  const statusUpdate = useCallback(
    async (consignmentId: string, status: StatusCode): Promise<Consignment> => {
      const response = await publicRoute({
        method: 'put',
        url: `${REACT_APP_API_BASE_URL}/consignments/${consignmentId}/status-update/${status}`,
        data: {},
      });
      return response;
    },
    [publicRoute]
  );

  const addSpecialInstruction = useCallback(
    async (consignmentId: string, text: string): Promise<Consignment> => {
      const response = await publicRoute({
        method: 'post',
        url: `${REACT_APP_API_BASE_URL}/consignments/${consignmentId}/special-instruction`,
        data: { text },
      });
      return response;
    },
    [publicRoute]
  );

  const addCheckInSheet = useCallback(
    async (consignmentId: string, checkInSheet: CheckInSheet): Promise<Consignment> => {
      const formData = serialize(checkInSheet, { allowEmptyArrays: true, indices: true }, undefined, 'checkInSheet');
      const response = await publicRoute({
        method: 'post',
        url: `${REACT_APP_API_BASE_URL}/consignments/${consignmentId}/check-in-sheet`,
        data: formData,
      });
      return response;
    },
    [publicRoute]
  );

  const list = useCallback(
    async (params: ConsignmentQueryParams): Promise<{ consignments: Consignment[]; total: number }> => {
      const response = await publicRoute({
        method: 'get',
        url: `${REACT_APP_API_BASE_URL}/consignments`,
        params: params,
      });
      return response;
    },
    [publicRoute]
  );

  const getPdfLabelA4V1 = useCallback(
    async (consignmentId: string, params: { totalLabel?: number }): Promise<string> => {
      const response = await publicRoute({
        method: 'get',
        url: `${REACT_APP_API_BASE_URL}/consignments/${consignmentId}/pdf-label-a4-v1`,
        params: params,
      });
      return response;
    },
    [publicRoute]
  );

  const getPdfBulkNote = useCallback(
    async (consignmentId): Promise<string> => {
      const response = await publicRoute({
        method: 'get',
        url: `${REACT_APP_API_BASE_URL}/consignments/${consignmentId}/pdf-bulk-note`,
      });
      return response;
    },
    [publicRoute]
  );

  const getPdfCheckInSheet = useCallback(
    async (consignmentId: string): Promise<string> => {
      const response = await publicRoute({
        method: 'get',
        url: `${REACT_APP_API_BASE_URL}/consignments/${consignmentId}/pdf-check-in-sheet`,
      });
      return response;
    },
    [publicRoute]
  );

  const reportCsvArchive = useCallback(
    async (params: ConsignmentQueryParams): Promise<string> => {
      const response = await publicRoute({
        method: 'get',
        url: `${REACT_APP_API_BASE_URL}/consignments/report-csv/archived`,
        params: params,
      });
      return response;
    },
    [publicRoute]
  );

  const emailCustomerReport = useCallback(
    async (consignmentId: string): Promise<string> => {
      const response = await publicRoute({
        method: 'post',
        url: `${REACT_APP_API_BASE_URL}/consignments/${consignmentId}/customer-report`,
      });
      return response;
    },
    [publicRoute]
  );

  return {
    create,
    assignDriver,
    cancelAssignment,
    moveToUnAssigned,
    statusUpdate,
    addSpecialInstruction,
    list,
    update,
    getPdfLabelA4V1,
    getPdfBulkNote,
    getPdfCheckInSheet,
    addCheckInSheet,
    reportCsvArchive,
    emailCustomerReport,
  };
}
