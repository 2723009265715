import React from 'react';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { ThemeProvider } from '@mui/system';
import './App.css';
import { SnackBarProvider } from './context/snackbar';
import { theme } from './context/theme';
import { BrowserRouter as Router } from 'react-router-dom';
import { UserProvider } from './context/user';
import AppRouter from './components/AppRouter';
import { ModalServiceProvider } from './context/modal';
import { GoogleApiProvider } from './context/googleApi';
import { SocketProvider } from './context/socket';

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SnackBarProvider>
          <GoogleApiProvider>
            <UserProvider>
              <SocketProvider>
                <ModalServiceProvider>
                  <Router>
                    <AppRouter />
                  </Router>
                </ModalServiceProvider>
              </SocketProvider>
            </UserProvider>
          </GoogleApiProvider>
        </SnackBarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default App;
