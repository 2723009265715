import { Box, Button, Theme, Typography } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import LoginImage from '../imgs/LoginImage.png';
import { createStyles, makeStyles } from '@mui/styles';
import { Formik, FormikHelpers } from 'formik';
import useValidators from '../hooks/useValidators';
import SnackBarContext from '../context/snackbar';
import { ConfirmationServiceContext } from '../context/modal';
import { PTextField } from '../pegasus/PTextField';
import PSwitch from '../pegasus/PSwitch';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import useAuthApiRoutes from '../hooks/api/useAuthApiRoutes';
import { useNavigate, useParams } from 'react-router-dom';
import UserContext from '../context/user';

const styles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      flex: 1,
    },
    cols: {
      flex: 1,
      height: '100%',
    },
    image: {
      width: '100%',
      objectFit: 'cover',
      height: '100%',
    },
    loginContainer: {
      marginTop: theme.spacing(25),
      marginLeft: theme.spacing(20),
      marginRight: theme.spacing(16),
    },
    form: {
      maxWidth: '430px',
    },
  })
);

interface MyFormValues {
  countryCode: string;
  email: string;
  password: string;
  keepMeLoggedIn: boolean;
}

const Login = () => {
  const classes = styles();
  const { countryCode = '' } = useParams<'countryCode'>();
  const navigate = useNavigate();
  const { validateEmail } = useValidators();
  const { showSnack } = useContext(SnackBarContext);
  const { showConfirmationModal, setOpenId } = useContext(ConfirmationServiceContext);
  const { saveUser, isLoggedIn } = useContext(UserContext);
  const { signIn, signOut, resetInstructions } = useAuthApiRoutes();
  const initialValues: MyFormValues = {
    countryCode: countryCode,
    email: '',
    password: '',
    keepMeLoggedIn: false,
  };
  const login = (values: MyFormValues, { setSubmitting }: FormikHelpers<MyFormValues>) => {
    signIn(values.email, values.password, values.countryCode)
      .then((_user) => {
        saveUser(_user);
        setSubmitting(false);
      })
      .catch(() => setSubmitting(false));
  };

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/');
    }
  }, [isLoggedIn, navigate]);

  useEffect(() => {
    signOut().then(() => {
      saveUser(undefined);
    });
  }, [saveUser, signOut]);

  return (
    <Box className={classes.container}>
      <Box className={classes.cols}>
        <img className={classes.image} src={LoginImage} alt="Login" />
      </Box>
      <Box className={classes.cols}>
        <Box className={classes.loginContainer}>
          <Typography variant="displayLargeBold" marginBottom={3.5}>
            Log In
          </Typography>
          <Formik initialValues={initialValues} validate={validateEmail} onSubmit={login}>
            {({ values, handleSubmit, isSubmitting, isValid }) => {
              const handleForgotPassword = async () => {
                if (!values.email) {
                  showSnack('Please enter an email address to send a link to', 'error');
                }
                const res = await showConfirmationModal({
                  title: 'Forgot your Password',
                  contentText: `Send forgot password link to ${values.email}?`,
                });
                if (res && res.confirmed === true) {
                  try {
                    await resetInstructions(values.email);
                    setOpenId(undefined);
                    showSnack('Please check your email for further instructions', 'success');
                  } catch (error) {
                    setOpenId(undefined);
                  }
                }
              };
              return (
                <form onSubmit={handleSubmit} className={classes.form}>
                  <PTextField label="Email Address" type="email" name="email" fullWidth />
                  <PTextField label="Password" type="password" name="password" fullWidth />
                  <PSwitch label="Keep me logged in" name="keepMeLoggedIn" fullWidth />

                  <Button
                    sx={{ mb: 2.5 }}
                    fullWidth
                    variant="contained"
                    type="submit"
                    disabled={!isValid || isSubmitting}
                  >
                    Log In
                    <ArrowForwardIcon sx={{ marginLeft: 1 }} />
                  </Button>

                  <Box>
                    <Typography variant="textSmall">Forgot your password?</Typography>
                    <Button size="small" variant="text" onClick={handleForgotPassword}>
                      <Typography variant="linkSmall" color="primary">
                        Create new password
                      </Typography>
                    </Button>
                  </Box>
                </form>
              );
            }}
          </Formik>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
