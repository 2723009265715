import { AppBar, Box, IconButton, Menu, MenuItem, Theme, Toolbar, Tooltip } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ModalContext, { ConfirmationServiceContext } from '../context/modal';
import UserContext from '../context/user';
import useAuthApiRoutes from '../hooks/api/useAuthApiRoutes';
import Logo from '../imgs/Logo.png';
import { PIcon } from '../pegasus/PIcon';

const styles = makeStyles((theme: Theme) =>
  createStyles({
    appbar: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      backgroundColor: theme.palette.common.white,
      borderRadius: '0px',
      borderBottomLeftRadius: theme.spacing(1.5),
      borderBottomRightRadius: theme.spacing(1.5),
      boxShadow: 'none',
    },
    image: { marginRight: theme.spacing(3) },
    childrenContainer: {
      flex: 1,
    },
    select: {
      width: 200,
    },
    settingsButton: {
      borderWidth: 2,
      borderStyle: 'solid',
      borderColor: theme.palette.primary.main,
      padding: theme.spacing(1.75),
      marginLeft: theme.spacing(2),
    },
  })
);

export default function Navbar({
  children,
  showSettings = true,
}: {
  children: React.ReactNode;
  showSettings?: boolean;
}) {
  const classes = styles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const { openDrawer } = useContext(ModalContext);
  const { signOut } = useAuthApiRoutes();
  const { isLoggedIn } = useContext(UserContext);
  const { countryCode = '' } = useParams<'countryCode'>();
  const { showConfirmationModal, setOpenId } = useContext(ConfirmationServiceContext);
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const showEditProfileModal = () => {
    openDrawer('edit-profile');
  };

  const handleLogout = async () => {
    const res = await showConfirmationModal({
      title: 'Log Out',
      contentText: 'Are you sure you want to log out of your UB Freight Account?',
    });
    if (res && res.confirmed === true) {
      setOpenId(undefined);
      signOut().then(() => {
        window.location.href = '/login';
      });
    }
  };

  return (
    <AppBar position="static" className={classes.appbar}>
      <Toolbar>
        <img className={classes.image} src={Logo} alt="UB Freight" />
        <Box className={classes.childrenContainer}>{children}</Box>

        {isLoggedIn && showSettings && (
          <>
            <Tooltip title="Account Menu">
              <IconButton
                className={classes.settingsButton}
                size="large"
                onClick={handleClick}
                aria-controls={open ? 'account-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
              >
                <PIcon name="settings" />
              </IconButton>
            </Tooltip>

            <Menu anchorEl={anchorEl} id="account-menu" open={open} onClose={handleClose} onClick={handleClose}>
              <MenuItem onClick={showEditProfileModal}>Edit Profile</MenuItem>
              <MenuItem
                onClick={() => {
                  navigate(`/operations/${countryCode}/address-book`, { replace: true });
                }}
              >
                Address Book
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigate(`/admin/accounts/${countryCode}`, { replace: true });
                }}
              >
                Team Accounts
              </MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}
