import React from 'react';
import { User } from '../../../../types';
import { createStyles, makeStyles } from '@mui/styles';
import { Box, Button, Typography } from '@mui/material';
import { getDriverInitials, getShortName } from '../../../hooks/useGetDriverName';
import { Colors } from '../../../pegasus/Colors';
import useFilterDriverConsignments from '../../../hooks/useFilterDriverConsignments';

const circleSize = 36;
const styles = makeStyles(() =>
  createStyles({
    driverRow: {
      display: 'flex',
      flexDirection: 'row',
      padding: 10,
      alignItems: 'center',
      borderTopLeftRadius: 6,
      borderBottomLeftRadius: 6,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      '&:hover': {
        backgroundColor: Colors.greyscale.offWhite,
      },
    },
    circle: {
      borderRadius: circleSize,
      height: circleSize,
      width: circleSize,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: 17,
    },
  })
);

export const DriverSelector = ({
  selectedDriver,
  drivers,
  onDriverClick,
}: {
  selectedDriver?: User;
  drivers: User[];
  onDriverClick: (driver: User) => void;
}) => {
  return (
    <Box display="flex" flexDirection="column">
      {drivers.map((driver) => {
        const isSelected = selectedDriver?._id === driver?._id;
        const handleDriverClick = () => {
          onDriverClick(driver);
        };
        return <DriverButton key={driver._id} driver={driver} onClick={handleDriverClick} isSelected={isSelected} />;
      })}
    </Box>
  );
};

const DriverButton = ({ driver, isSelected, onClick }: { driver: User; isSelected: boolean; onClick: () => void }) => {
  const classes = styles();
  const driverConsignmentCount = useFilterDriverConsignments(driver).length;

  return (
    <Button
      key={driver._id}
      className={classes.driverRow}
      sx={{ backgroundColor: isSelected ? Colors.greyscale.offWhite : 'unset' }}
      onClick={onClick}
    >
      <Box className={classes.circle} sx={{ backgroundColor: driver.driverInformation?.color }}>
        <Typography variant="linkXSmall" color="white">
          {getDriverInitials(driver)}
        </Typography>
      </Box>
      <Box flex={1} textAlign="left">
        <Typography variant="linkXSmall">{getShortName(driver)}</Typography>
        <br />
        <Typography variant="linkXSmall" fontWeight={400}>
          {driver.driverInformation?.currentRegistration || ''}
        </Typography>
      </Box>
      {driverConsignmentCount ? (
        <Box className={classes.circle} sx={{ backgroundColor: Colors.greyscale.coolGrey }}>
          <Typography variant="linkXSmall" color="white">
            {driverConsignmentCount}
          </Typography>
        </Box>
      ) : null}
    </Button>
  );
};
