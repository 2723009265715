import { TablePagination } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useEffect, useState } from 'react';
import { DEFAULT_PAGE, ITEM_PER_PAGE } from '../../../constants/table';
import ConsignmentContext from '../../../context/consignments';
import useConsignmentApiRoutes from '../../../hooks/api/useConsignmentApiRoutes';
import { Colors } from '../../../pegasus/Colors';

const styles = makeStyles(() =>
  createStyles({
    paginationRoot: {
      color: Colors.primary.main,
      '& .MuiTablePagination-displayedRows': {
        color: Colors.primary.main,
        fontWeight: 600,
        fontSize: '13px',
        letterSpacing: '0.25px',
        lineHeight: '18px',
      },
    },
  })
);

const TableFooter = () => {
  const classes = styles();
  const { totalConsignments, queryParams, setConsignments, setTotalConsignments, setIsLoading } =
    useContext(ConsignmentContext);
  const [page, setPage] = useState(queryParams.page);
  const { list: listConsignments } = useConsignmentApiRoutes();

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);
  };
  useEffect(() => {
    setIsLoading(true);
    listConsignments({ ...queryParams, page })
      .then((result) => {
        setIsLoading(false);
        setTotalConsignments(result.total);
        setConsignments(result.consignments);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [page, listConsignments, queryParams, setTotalConsignments, setConsignments, setIsLoading]);
  return (
    <>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={totalConsignments}
        rowsPerPage={queryParams.itemsPerPage || ITEM_PER_PAGE}
        page={page || DEFAULT_PAGE}
        onPageChange={handleChangePage}
        classes={{ root: classes.paginationRoot }}
      />
    </>
  );
};

export default TableFooter;
