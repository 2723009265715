import { useCallback, useContext } from 'react';
import SnackBarContext from '../context/snackbar';

export default function useHandleError() {
  const { showSnack } = useContext(SnackBarContext);

  const handleError = useCallback(
    //eslint-disable-next-line
    (error: any) => {
      let errorText;
      console.log('handleError: ', error);
      if (error.response && error.response.data) {
        if (error.response.data.message) {
          errorText = error.response.data.message;
        } else if (error.response.data.errors) {
          errorText = error.response.data.errors
            //eslint-disable-next-line
            .map((e: any) => {
              if (e.title && e.detail) {
                return `${e.title} => ${e.detail}`;
              } else {
                return JSON.stringify(e);
              }
            })
            .join('\n');
        } else {
          errorText = error.response.data;
        }
      } else if (error.message) {
        errorText = error.message;
      } else {
        errorText = JSON.stringify(error);
      }
      showSnack(`Error: ${errorText}`, 'error');
      throw error;
    },
    [showSnack]
  );
  return handleError;
}
