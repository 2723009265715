import React from 'react';
import { Box, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { Colors } from '../../../../pegasus/Colors';

export const InfoWindowWidth = 200;

const styles = makeStyles((theme: Theme) =>
  createStyles({
    infoWindowContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: InfoWindowWidth,
    },
    infoBox: {
      backgroundColor: Colors.greyscale.offWhite,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      borderRadius: theme.spacing(2),
    },
    infoBoxArrow: {
      borderTop: `solid 12px ${Colors.greyscale.offWhite}`,
      borderLeft: 'solid 12px transparent',
      borderRight: 'solid 12px transparent',
    },
  })
);

export const InfoBoxContentsWrapper = ({ children }: { children: React.ReactNode }) => {
  const classes = styles();
  return (
    <Box className={classes.infoWindowContainer}>
      <Box className={classes.infoBox}>{children}</Box>
      <Box className={classes.infoBoxArrow}></Box>
    </Box>
  );
};
