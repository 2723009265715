import React, { useContext } from 'react';
import ModalContext from '../../../context/modal';
import { FormikHelpers } from 'formik';
import { SubmitButton } from '../../../pegasus/PSubmit';
import UserForm from './UserForm';
import { User } from '../../../../types';
import SnackBarContext from '../../../context/snackbar';
import useUserApiRoutes from '../../../hooks/api/useUserApiRoutes';

const NewUserModal = ({ countryCode, onUserCreate }: { countryCode: string; onUserCreate?: (user: User) => void }) => {
  const { closeDrawer } = useContext(ModalContext);
  const { invite } = useUserApiRoutes();
  const { showSnack } = useContext(SnackBarContext);

  const handleSubmit = (values: Partial<User>, { setSubmitting }: FormikHelpers<Partial<User>>) => {
    invite(values)
      .then((user) => {
        setSubmitting(false);
        showSnack('User Invited!', 'success');
        closeDrawer('new-user');
        if (onUserCreate) {
          onUserCreate(user);
        }
      })
      .catch(() => setSubmitting(false));
  };

  return (
    <UserForm
      onSubmit={handleSubmit}
      initialValues={{
        type: 'operations',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        countryCode: countryCode,
        depots: [],
      }}
    >
      <SubmitButton color="primary" sx={{ marginRight: 1 }}>
        Invite To Team
      </SubmitButton>
    </UserForm>
  );
};

export default NewUserModal;
