import React, { useContext } from 'react';
import { TableField } from '../../../../types';
import TableRow from './TableRow';
import TableHeader from './TableHeader';
import { AddressField, ConsignmentField } from './components';
import ConsignmentContext from '../../../context/consignments';
import { CheckInField } from '../../Operations/tables/components';
import { CheckInSheetButton } from '../components/CheckInSheetButton';
import { Box } from '@mui/material';

const TableFields: TableField[] = [
  {
    field: 'consignmentId',
    label: 'CONSIGNMENT #',
    columns: 3,
    // eslint-disable-next-line react/display-name
    renderField: (consignment) => <ConsignmentField consignment={consignment} />,
  },
  {
    field: 'sender',
    label: 'ORIGIN',
    columns: 3,
    // eslint-disable-next-line react/display-name
    renderField: (consignment) => <AddressField location={consignment.sender} />,
  },
  {
    field: 'receiver',
    label: 'DESTINATION',
    columns: 3,
    // eslint-disable-next-line react/display-name
    renderField: (consignment) => <AddressField location={consignment.receiver} />,
  },
  {
    field: 'checkInSheet.screenAtDate',
    label: 'CHECK IN SHEET',
    columns: 3,
    // eslint-disable-next-line react/display-name
    renderField: (consignment) => <CheckInField consignment={consignment} />,
  },
  {
    field: 'actions',
    label: '',
    columns: 2,
    // eslint-disable-next-line react/display-name
    renderField: (consignment) => <CheckInSheetButton consignment={consignment} />,
  },
];
const totalColumns = TableFields.reduce((accum, field) => accum + field.columns, 0);

const CompletedTable = () => {
  const { consignments } = useContext(ConsignmentContext);

  return (
    <>
      <TableHeader tableFields={TableFields} columnCount={totalColumns} />
      <Box overflow="auto">
        {consignments
          .filter((c) => ['complete'].includes(c.status))
          .sort((a, b) => a.createdAt - b.createdAt)
          .map((consignment) => {
            return (
              <TableRow
                key={consignment._id}
                consignment={consignment}
                tableFields={TableFields}
                columnCount={totalColumns}
              />
            );
          })}
      </Box>
    </>
  );
};

export default CompletedTable;
