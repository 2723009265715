import React from 'react';
import { Box, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { Colors } from '../../../pegasus/Colors';
import { Consignment } from '../../../../types';
import { PIcon } from '../../../pegasus/PIcon';

const styles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    },
    vDash: {
      width: 3,
      height: 10,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  })
);

export const StatusMarker = ({ consignment }: { consignment: Consignment }) => {
  const classes = styles();
  const roundMarkerCount = consignment.orderType === 'transfer' ? 2 : 1;
  return (
    <Box className={classes.container}>
      {Array.from(Array(roundMarkerCount).keys()).map((index) => {
        return (
          <React.Fragment key={index}>
            <PIcon
              name="markerRound"
              sx={{ color: consignment.pickedUpDate ? Colors.primary.main : Colors.greyscale.coolGrey500 }}
            />
            <Box
              className={classes.vDash}
              sx={{ backgroundColor: consignment.pickedUpDate ? Colors.primary.main : Colors.greyscale.coolGrey500 }}
            ></Box>
            <Box
              className={classes.vDash}
              sx={{ backgroundColor: consignment.pickedUpDate ? Colors.primary.main : Colors.greyscale.coolGrey500 }}
            ></Box>
          </React.Fragment>
        );
      })}
      <PIcon
        name="marker"
        sx={{
          color:
            consignment.status === 'failed' || consignment.status === 'complete'
              ? Colors.primary.main
              : Colors.greyscale.coolGrey500,
        }}
      />
    </Box>
  );
};
