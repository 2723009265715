import { Checkbox, CheckboxProps, FormControlLabel, FormControlLabelProps } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import { Colors } from './Colors';
import { useFormikContext } from 'formik';
import get from 'lodash/get';
import { ErrorLabel } from './ErrorLabel';
import React from 'react';
export const PCheckbox = (
  props: {
    label: FormControlLabelProps['label'];
    name: string;
    size?: 'small' | 'inherit' | 'medium' | 'large';
  } & CheckboxProps
) => {
  const { label, name, size, ...otherProps } = props;
  const { values, handleChange, handleBlur, errors } = useFormikContext();
  const error = get(errors, name);
  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            name={name}
            checked={name ? get(values, name, false) : false}
            onChange={handleChange}
            onBlur={handleBlur}
            icon={<CircleIcon fontSize={size || 'large'} sx={{ color: Colors.grey.bg }} />}
            checkedIcon={<CheckCircleIcon fontSize={size || 'large'} />}
            {...otherProps}
          />
        }
        label={label}
      />
      {error ? <ErrorLabel errorText={error} /> : null}
    </>
  );
};
