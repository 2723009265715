import { Box, SxProps, Theme } from '@mui/system';
import React from 'react';
import EditIcon from './Icons/Edit.svg';
import ProfileIcon from './Icons/Profile.svg';
import SettingsIcon from './Icons/Settings.svg';
import PlusIcon from './Icons/Plus.svg';
import TruckIcon from './Icons/Truck.svg';
import BagIcon from './Icons/Bag.svg';
import FlagIcon from './Icons/Flag.svg';
import TagIcon from './Icons/Tag.svg';
import TimeIcon from './Icons/Time.svg';
import ForwardIcon from './Icons/Forward.svg';
import BackArrowIcon from './Icons/BackArrow.svg';
import PlusUserCircleIcon from './Icons/PlusUserCircle.svg';
import MarkerIcon from './Icons/Marker.svg';
import MarkerRoundIcon from './Icons/MarkerRound.svg';
import TicketIcon from './Icons/Ticket.svg';
import PrinterIcon from './Icons/Printer.svg';
import ReadBookIcon from './Icons/ReadBook.svg';
import Check from './Icons/Check.svg';
import UnCheck from './Icons/UnCheck.svg';
import ChevronForward from './Icons/ChevronForward.svg';
import ChevronBack from './Icons/ChevronBack.svg';
import SearchIcon from './Icons/Search.svg';
import FileIcon from './Icons/File.svg';

export const customIconMap = {
  edit: EditIcon,
  profile: ProfileIcon,
  settings: SettingsIcon,
  plus: PlusIcon,
  plusUserCircle: PlusUserCircleIcon,
  truck: TruckIcon,
  bag: BagIcon,
  flag: FlagIcon,
  forward: ForwardIcon,
  backArrow: BackArrowIcon,
  tag: TagIcon,
  time: TimeIcon,
  marker: MarkerIcon,
  markerRound: MarkerRoundIcon,
  ticket: TicketIcon,
  printer: PrinterIcon,
  readBook: ReadBookIcon,
  check: Check,
  unCheck: UnCheck,
  chevronForward: ChevronForward,
  chevronBack: ChevronBack,
  searchIcon: SearchIcon,
  file: FileIcon,
};
export const PIcon = ({ name, sx, size }: { name: keyof typeof customIconMap; sx?: SxProps<Theme>; size?: number }) => {
  // const MyComponent = customIconMap[name] as keyof JSX.IntrinsicElements;
  const MyComponent = customIconMap[name];
  return (
    <Box display="flex" sx={{ width: size, height: size, ...sx }}>
      <MyComponent />
    </Box>
  );
};
