import React, { useContext, useEffect, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import UserContext from './user';
import { REACT_APP_SOCKET_DOMAIN } from '../env';
import { StatusCode } from '../../types';
const SocketContext = React.createContext<{
  socket?: Socket;
  updatedStatusCodes: StatusCode[];
  setUpdatedStatusCodes: React.Dispatch<React.SetStateAction<StatusCode[]>>;
}>({
  updatedStatusCodes: [],
  setUpdatedStatusCodes: () => undefined,
  socket: undefined,
});
export default SocketContext;

export const SocketProvider = ({ children }: { children: React.ReactNode }) => {
  const { isLoggedIn } = useContext(UserContext);
  const [socket, setSocket] = useState<Socket>();
  const [updatedStatusCodes, setUpdatedStatusCodes] = useState<StatusCode[]>([]);

  useEffect(() => {
    const newSocket = io(REACT_APP_SOCKET_DOMAIN, {
      transports: ['websocket'],
      upgrade: false,
    });
    setSocket(newSocket);
    return () => {
      newSocket.close();
    };
  }, [setSocket]);

  useEffect(() => {
    if (socket) {
      socket.on('connect', () => {
        console.log('i am connected');
        // socket.emit('join', user)
      });
    }
  }, [socket]);

  useEffect(() => {
    if (socket) {
      if (isLoggedIn) {
        socket.connect();
      } else {
        socket.disconnect();
      }
    }
  }, [isLoggedIn, socket]);

  return (
    <SocketContext.Provider
      value={{
        socket,
        updatedStatusCodes,
        setUpdatedStatusCodes,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};
