// type PartialPick<T, K extends keyof T> = {
//   [P in K]?: T[P];
// };

import countries from './client/constants/countries';
import orderTypes from './client/constants/orderTypes';
import statuses from './client/constants/statuses';

export type BaseFields = {
  _id: string;
  createdAt: number;
  updatedAt: number;
};

const countryCodes = [...countries.map((t) => t.code)] as const;

export type User = BaseFields & {
  depots: (string | Depot)[];
  depotIds: string[];
  firstName: string;
  lastName: string;
  phone: string;
  email?: string | null;
  active: boolean;
  countryCode: typeof countryCodes[number];
  password?: string; // for setting screen only
  type?: 'admin' | 'warehouse-manager' | 'dispatch' | 'operations' | 'driver' | 'branch-manager';
  countryCodeSignIn?: string;
  driverInformation?: {
    color?: string; // to show driver on map
    isActive?: boolean; // to show driver on map
    lastKnownPosition?: {
      // to show driver on map
      lat: number;
      lng: number;
      seenAt?: string;
      source?: 'mobile-app' | 'navman';
    };
    currentRegistration?: string;
  };
};

export type DrawerType =
  | 'new-consignment'
  | 'view-consignment'
  | 'edit-consignment'
  | 'duplicate-consignment'
  | 'edit-check-in-sheet'
  | 'assign-driver'
  | 'new-user'
  | 'new-driver'
  | 'new-depot'
  | 'edit-user'
  | 'edit-profile'
  | 'edit-address-book'
  | undefined;

export const areaZones = [
  { name: 'Commercial', code: 'commercial' },
  { name: 'Residential', code: 'residential' },
  { name: 'Rural', code: 'rural' },
];
const areaZoneCodes = [...areaZones.map((t) => t.code)] as const;

export const lineItemTypes = [
  { name: 'Pallets', code: 'carton' },
  { name: 'Cartons', code: 'pallet' },
  { name: 'Crates', code: 'crate' },
  { name: 'Packages', code: 'package' },
  { name: 'Drums/Gallons', code: 'drum-gallon' },
  { name: 'Bails/Rolls', code: 'bail-roll' },
  { name: 'Pieces (Misc)', code: 'piece' },
];
const lineItemTypeCodes = [...lineItemTypes.map((t) => t.code)] as const;

export type AddressBookLocation = BaseFields & {
  companyName: string;
  address: string;
  additionalInfo: string;
  location?: { lat: number; lng: number };
  contactName: string;
  phone: string;
  email: string;
  contactEmails: string[];
  areaZone: typeof areaZoneCodes[number];
  countryCode: typeof countryCodes[number];
  originCountryCode?: typeof countryCodes[number];
  state?: string;
};

export type Depot = Omit<AddressBook, 'areaZone'>;

export type AddressBook = AddressBookLocation & {
  userId: string;
  // pickUp: boolean;
  milestones: boolean;
  // tracking: boolean;
  labels: boolean;
  // consignment: boolean;
};

const statusCodes = [...statuses.map((t) => t.code)] as const;
export type StatusCode = typeof statusCodes[number];

const orderTypeCodes = [...orderTypes.map((t) => t.code)] as const;
export type OrderType = typeof orderTypeCodes[number];
export type DeadRun = {
  createdBy?: string;
  createdAt: number;
  reason: string;
  photos: Image[];
};
export type CheckInSheet = {
  screenAtDate: number;
  screenedBy: string;
  lineItems: (LineItem & { marks?: string })[];
  knownShipper: boolean;
  sufficientPackaging: boolean;
  ipsmPallet: boolean;
  statementOfContent: boolean | null;
  tamperEvidentForm: boolean;
  comments: string;
  recievedBy: string;
  recievedBySignature?: Image;
};

export type CalculatedBy = 'unitType' | 'totalShipment';
export type Consignment = BaseFields & {
  countryCode: typeof countryCodes[number];
  consignmentId: string;
  orderType: OrderType;
  depot: string | Depot;
  depotId: string;
  sender: Omit<AddressBook, 'userId' | keyof BaseFields>;
  receiver: Omit<AddressBook, 'areaZone' | 'userId' | 'milestones' | 'labels' | keyof BaseFields>;
  status: StatusCode;
  assignedAt?: number;
  preferredPickUpTime?: number;
  preferredDeliveryTime?: number;
  estimatedDeliveryTime?: number;
  purchaseOrderNumber: string;
  supplierName: string;
  reference: string;
  deliveryInstructions: string;
  specialInstructions: {
    text: string;
    createdAt: number;
    updatedByName?: string;
    importance?: 'low' | 'medium' | 'high';
  }[];
  signatureRequired: boolean;
  photoPodRequired: boolean;
  urgent: boolean;
  tailLiftRequired: boolean;
  customsMpiFoodSafety: boolean;
  fragile: boolean;
  saturdayDelivery: boolean;
  goodsType: 'general' | 'dangerous';
  temperatureControlRequired: boolean;
  assignedToDriverLeg1?: string | User;
  assignedToDriverLeg2?: string | User;
  assignedToDriverLeg1Id?: string;
  assignedToDriverLeg2Id?: string;
  totalItemsScanned?: number;
  totalItemsScannedLeg2?: number;
  pickedUpDate?: number;
  penaltyTimeAdded?: number;
  driverNotes?: string;
  driverNotesLeg2?: string;
  proofOfDelivery?: {
    customerName?: string;
    photos: Image[];
    customerSignature?: Image;
    rating: 'sad' | 'neutral' | 'happy';
    deliveryNotes?: string;
  };
  deadRuns?: DeadRun[];
  checkInSheet?: CheckInSheet;
  lineItems?: LineItem[];
  calculateVolumeBy: CalculatedBy;
  archived: boolean;
};

export const booleanFields: (keyof Consignment)[] = [
  'signatureRequired',
  'customsMpiFoodSafety',
  'photoPodRequired',
  'fragile',
  'urgent',
  'saturdayDelivery',
  'temperatureControlRequired',
  'tailLiftRequired',
];

export type Image = {
  bucket: string;
  date: number;
  filename: string;
  filesize: number;
  path: string;
  uid: string;
  signedUrl?: string;
};

export type LineItem = {
  type: typeof lineItemTypeCodes[number];
  units: number;
  length: number;
  width: number;
  height: number;
  weight: number;
  totalCube: number;
};

export type TableFieldBase = {
  field: string;
  label: string;
  columns: number;
  position?: 'center' | 'right' | 'left';
  default?: string;
  formatDate?: boolean;
};
export type TableField = TableFieldBase & {
  renderField?: (consignment: Consignment) => React.ReactNode;
};
export type UserTableField = TableFieldBase & {
  renderField?: (user: User & { depotInfo: Depot[] }) => React.ReactNode;
};

export type AddressBookTableField = TableFieldBase & {
  renderField?: (addressBook: AddressBook) => React.ReactNode;
};

export type Vehicle = {
  registrationNumber: string;
  description: string;
  lastKnownPosition?: {
    // to show on map
    lat: number;
    lng: number;
  };
};
