import React, { useContext } from 'react';
import { Grid, Typography } from '@mui/material';
import { ReadOnlyField } from './ReadOnlyField';
import ModalContext from '../../../context/modal';

const SenderSectionReadOnly = ({ contextColor }: { contextColor: string }) => {
  const { consignment } = useContext(ModalContext);
  return (
    <>
      <Typography variant="displayXSmallBold" color={contextColor} mb={3}>
        Sender
      </Typography>
      <Grid container spacing={1} columns={3} mb={5}>
        <Grid item xs={1}>
          <ReadOnlyField label="COMPANY NAME" value={consignment?.sender.companyName} />
        </Grid>
        <Grid item xs={2}>
          <ReadOnlyField label="ADDRESS" value={consignment?.sender.address} />
        </Grid>
      </Grid>
      {consignment?.sender.additionalInfo ? (
        <Grid container spacing={1} columns={3} mb={5}>
          <Grid item xs={3}>
            <ReadOnlyField label="ADDITIONAL ADDRESS INFORMATION" value={consignment?.sender.additionalInfo} />
          </Grid>
        </Grid>
      ) : null}

      <Grid container spacing={1} columns={3} mb={5}>
        <Grid item xs={1}>
          <ReadOnlyField label="CONTACT NAME" value={consignment?.sender.contactName} />
        </Grid>
        <Grid item xs={1}>
          <ReadOnlyField label="PHONE #" value={consignment?.sender.phone} />
        </Grid>
        <Grid item xs={1}>
          <ReadOnlyField label="EMAIL" value={consignment?.sender.email} />
        </Grid>
      </Grid>

      {consignment?.sender.contactEmails && (
        <Grid container spacing={1} columns={3} mb={5}>
          <Grid item xs={3}>
            <ReadOnlyField label="ADDITIONAL EMAILS" value={consignment?.sender.contactEmails.join(', ')} />
          </Grid>
        </Grid>
      )}

      {/* <Grid container spacing={1} columns={3} mb={5}>
        <Grid item xs={1}>
          <ReadOnlyField label="AREA ZONE" value="TODO area zone not specified on receiver??" />
        </Grid>
        <Grid item xs={2}>
          <ReadOnlyField label="DELIVERY INSTRUCTIONS" value={consignment?.deliveryInstructions} />
        </Grid>
      </Grid> */}
    </>
  );
};

export default SenderSectionReadOnly;
