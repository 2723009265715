import React from 'react';
import { Typography, Box, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { Colors } from '../../../pegasus/Colors';

const styles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: Colors.primary.background,
      borderRadius: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      marginRight: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
      alignSelf: 'center',
      marginBottom: theme.spacing(1),
    },
  })
);

export const OrderBadge = ({ label, count }: { label: string; count: number }) => {
  const classes = styles();
  return (
    <Box className={classes.container}>
      <Typography variant="textXSmall" color="primary">
        {label}
        <Typography variant="textXSmall" color="primary" fontWeight={600} ml={1}>
          {count}
        </Typography>
      </Typography>
    </Box>
  );
};
