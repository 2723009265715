import React, { useContext } from 'react';
import ModalContext from '../../../context/modal';
import { Formik, FormikHelpers } from 'formik';
import { Consignment, User } from '../../../../types';
import SnackBarContext from '../../../context/snackbar';
import useConsignmentApiRoutes from '../../../hooks/api/useConsignmentApiRoutes';
import { SubmitButton } from '../../../pegasus/PSubmit';
import DriverSelect from '../../../components/form/DriverSelect';
import { createStyles, makeStyles } from '@mui/styles';
import { Box, Theme } from '@mui/system';
import addHours from 'date-fns/addHours';
import { PDateTimePicker } from '../../../pegasus/PDateTimePicker';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      flex: 1,
    },
    marginBottom: {
      display: 'flex',
      marginBottom: theme.spacing(2),
    },
  })
);

type FieldToAssign = 'assignedToDriverLeg1' | 'assignedToDriverLeg2';
const AssignDriverModal = () => {
  const { consignment, closeDrawer } = useContext(ModalContext);
  const classes = useStyles();
  const { assignDriver } = useConsignmentApiRoutes();
  const { showSnack } = useContext(SnackBarContext);
  const initialValues: Partial<Consignment> = { ...consignment } || {};
  const now = new Date();
  const todayStart = new Date().setHours(7, 0, 0, 0); // today at 7 am
  const fieldToAssign: FieldToAssign =
    consignment?.status === 'atDepot' ||
    consignment?.status === 'assignedLeg2' ||
    (consignment?.status === 'failed' && consignment.assignedToDriverLeg1Id)
      ? 'assignedToDriverLeg2'
      : 'assignedToDriverLeg1';

  const label = fieldToAssign === 'assignedToDriverLeg2' ? 'Select Driver for Leg 2' : 'Select Driver';

  const assignedToDriver: User | undefined = initialValues[fieldToAssign] as User;
  if (assignedToDriver?._id) {
    initialValues[fieldToAssign] = assignedToDriver._id;
  }
  initialValues.estimatedDeliveryTime =
    now.getHours() < 14
      ? addHours(Math.max(now.getTime(), todayStart), 4).getTime()
      : addHours(todayStart, 28).getTime();

  const handleSubmit = (values: Partial<Consignment>, { setSubmitting }: FormikHelpers<Partial<Consignment>>) => {
    assignDriver(values)
      .then(() => {
        setSubmitting(false);
        showSnack('Driver assigned!', 'success');
        closeDrawer('assign-driver');
      })
      .catch(() => setSubmitting(false));
  };

  const validate = (values: Pick<Consignment, FieldToAssign>) => {
    const errors: { assignedToDriverLeg1?: string; assignedToDriverLeg2?: string } = {};
    if (!values[fieldToAssign]) {
      errors[fieldToAssign] = 'Required';
    }
    return errors;
  };

  return (
    <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit}>
      {({ handleSubmit, isValid, isSubmitting }) => {
        return (
          <form onSubmit={handleSubmit} className={classes.form}>
            <Box mb={6}>
              <DriverSelect name={fieldToAssign} label={label} />
              <PDateTimePicker
                containerClass={classes.marginBottom}
                name="estimatedDeliveryTime"
                fullWidth
                label="Estimated Delivery Time"
              />
              <PDateTimePicker
                containerClass={classes.marginBottom}
                name="preferredPickUpTime"
                fullWidth
                label="Preferred PickUp Time"
              />
              <PDateTimePicker
                containerClass={classes.marginBottom}
                name="preferredDeliveryTime"
                fullWidth
                label="Preferred Delivery Time"
              />
            </Box>
            <SubmitButton color="primary" disabled={!isValid || isSubmitting}>
              Assign To Driver
            </SubmitButton>
          </form>
        );
      }}
    </Formik>
  );
};

export default AssignDriverModal;
