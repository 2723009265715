import React, { useContext, useState } from 'react';
import ModalContext from '../../../context/modal';
import { Divider, Grid, MenuItem, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { Formik, FormikHelpers } from 'formik';
import { SubmitButton } from '../../../pegasus/PSubmit';
import { PTextFieldMemo, PTextFieldReadOnly, useGetFormikTextFields } from '../../../pegasus/PTextField';
import { PSelect } from '../../../pegasus/PSelect';
import { ConsignmentFormValues } from './ConsignmentForm';
import { PDateTimePicker } from '../../../pegasus/PDateTimePicker';
import { useGetDriverFullName } from '../../../hooks/useGetDriverName';
import { Colors } from '../../../pegasus/Colors';
import CheckInTable from './CheckInTable';
import UserContext from '../../../context/user';
import { PDatepicker } from '../../../pegasus/PDatepicker';
import UserSelect from '../../../components/form/UserSelect';
import AppDrawer from '../../../components/Modals/AppDrawer';
import SignaturePad from '../../../components/SignaturePad';
import NewUserModal from '../../Admin/user/NewUserModal';
import { CheckInSheet, User } from '../../../../types';
import CheckInBooleanFields from './CheckInBooleanFields';
import { get } from 'lodash';
import useConsignmentApiRoutes from '../../../hooks/api/useConsignmentApiRoutes';
import SnackBarContext from '../../../context/snackbar';

type AddUserFields = 'checkInSheet.screenedBy' | 'checkInSheet.recievedBy' | undefined;

const CheckInSheetModal = () => {
  const { consignment, closeDrawer } = useContext(ModalContext);
  const { showSnack } = useContext(SnackBarContext);
  const { addCheckInSheet } = useConsignmentApiRoutes();
  const { user } = useContext(UserContext);
  const [userModalField, setUserModalField] = useState<AddUserFields>(undefined);
  const getFormikTextFields = useGetFormikTextFields();

  const initialValues: ConsignmentFormValues = consignment
    ? {
        ...consignment,
        checkInSheet: {
          screenAtDate: new Date().getTime(),
          screenedBy: user?._id || '',
          lineItems: (consignment.lineItems || []).map((i) => ({ ...i, marks: '' })),
          knownShipper: false,
          sufficientPackaging: false,
          ipsmPallet: false,
          statementOfContent: null,
          tamperEvidentForm: false,
          comments: '',
          recievedBy: user?._id || '',
        },
      }
    : {};
  const driverFullName = useGetDriverFullName(consignment);
  const classes = useStyles();

  const validate = (values: Partial<ConsignmentFormValues>) => {
    // eslint-disable-next-line no-unused-vars
    const errors: Partial<{ [key in keyof CheckInSheet]: string }> = {};
    (['recievedBySignature'] as Array<keyof CheckInSheet>).forEach((k) => {
      if (values.checkInSheet && !values.checkInSheet[k] && values.checkInSheet[k] !== false) {
        errors[k] = 'Required';
      }
    });
    return errors;
  };

  const save = (values: ConsignmentFormValues, { setSubmitting }: FormikHelpers<ConsignmentFormValues>) => {
    if (consignment && values.checkInSheet) {
      addCheckInSheet(consignment._id, values.checkInSheet)
        .then(() => {
          setSubmitting(false);
          showSnack('Check in sheet added!', 'success');
          closeDrawer('edit-check-in-sheet');
        })
        .catch(() => setSubmitting(false));
    }
  };

  return consignment ? (
    <Formik initialValues={initialValues} validate={validate} onSubmit={save}>
      {(props) => {
        const { setFieldValue, handleSubmit, values } = props;
        const formikProps = getFormikTextFields(props);

        const handleUserCreate = (user: User) => {
          if (userModalField) {
            setFieldValue(userModalField, user._id);
            setUserModalField(undefined);
          }
        };

        const handleSignatureClear = () => {
          setFieldValue('checkInSheet.recievedBySignature', undefined);
        };

        const handleCaptureSignature = (blob: Blob | null) => {
          setFieldValue('checkInSheet.recievedBySignature', blob);
        };

        return (
          <form onSubmit={handleSubmit} className={classes.form}>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <PTextFieldReadOnly value={`#${consignment.consignmentId}`} fullWidth label="Consignment Id" />
              </Grid>
              <Grid item xs={4}>
                <PDateTimePicker
                  disabled={true}
                  name="pickedUpDate"
                  fullWidth
                  label="Picked Up Date"
                  inputFormat="dd MMM, h:mm a"
                />
              </Grid>
              <Grid item xs={4}>
                <PTextFieldReadOnly value={driverFullName} fullWidth label="Delivered By (Driver)" />
              </Grid>
            </Grid>
            <Grid container spacing={3} mb={3}>
              <Grid item xs={4}>
                <Typography variant="textXSmall" display="block" color={Colors.greyscale.label}>
                  Shipper
                </Typography>
                <Typography variant="textSmall" display="block" fontWeight="bold" color={Colors.greyscale.offBlack}>
                  {consignment.sender.companyName}
                </Typography>
                <Typography variant="textSmall" display="block" color={Colors.greyscale.offBlack}>
                  {consignment.sender.address}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <PTextFieldMemo
                  fullWidth
                  name="checkInSheet.consigneeCompanyName"
                  label="Consignee (Company Name)"
                  value={get(values, 'checkInSheet.consigneeCompanyName')}
                  {...formikProps}
                />
              </Grid>
              <Grid item xs={4}>
                <PSelect name="checkInSheet.mode" label="Mode" fullWidth>
                  {['Air', 'Sea'].map((m) => (
                    <MenuItem key={m} value={m}>
                      {m}
                    </MenuItem>
                  ))}
                </PSelect>
              </Grid>
            </Grid>
            <Divider sx={{ mb: 3 }} />
            <CheckInTable values={values} {...formikProps} />
            <Grid container spacing={3} mb={3}>
              <Grid item xs={4}>
                <PDatepicker name="checkInSheet.screenAtDate" fullWidth label="Date Screened" />
              </Grid>
              <Grid item xs={4}>
                {/* hide when adding a user to esure depots are reloaded */}
                {userModalField === undefined ? (
                  <UserSelect
                    onAddUser={() => setUserModalField('checkInSheet.screenedBy')}
                    name="checkInSheet.screenedBy"
                    label="Screened By"
                    depot={consignment.depotId}
                  />
                ) : null}
              </Grid>
            </Grid>
            <Divider sx={{ mb: 3 }} />
            <CheckInBooleanFields />
            <Grid container spacing={3} mb={3}>
              <Grid item xs={7}>
                <PTextFieldMemo
                  name="checkInSheet.comments"
                  value={get(values, 'checkInSheet.comments')}
                  fullWidth
                  label="Comments / Remarks..."
                  multiline
                  minRows={6}
                  {...formikProps}
                />
              </Grid>
              <Grid item xs={5}>
                {/* hide when adding a user to esure depots are reloaded */}
                {userModalField === undefined ? (
                  <UserSelect
                    onAddUser={() => setUserModalField('checkInSheet.recievedBy')}
                    name="checkInSheet.recievedBy"
                    label="Recieved By"
                    depot={consignment.depotId}
                    sx={{ marginBottom: 3 }}
                  />
                ) : null}
                <SignaturePad onClear={handleSignatureClear} onCaptureSignature={handleCaptureSignature} />
              </Grid>
            </Grid>
            <SubmitButton>Save & Email PDF to Ops</SubmitButton>
            <AppDrawer
              headerText="Add User"
              open={!!userModalField}
              drawerType="new-user"
              onClose={() => setUserModalField(undefined)}
            >
              <NewUserModal countryCode={consignment?.countryCode || ''} onUserCreate={handleUserCreate} />
            </AppDrawer>
          </form>
        );
      }}
    </Formik>
  ) : null;
};

export default CheckInSheetModal;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      position: 'relative',
      overflow: 'auto',
      padding: theme.spacing(6),
      backgroundColor: Colors.greyscale.offWhite,
      width: '100%',
    },
  })
);
