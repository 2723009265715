import { useFormikContext } from 'formik';
import React from 'react';
import { PSelectAsync } from '../../pegasus/PSelectAsync';
import get from 'lodash/get';
import countries, { Country } from '../../constants/countries';

export default function CountrySelect({
  name,
  label,
  onChange,
}: {
  name: string;
  label: string;
  onChange?: () => void;
}) {
  const { setFieldValue, values } = useFormikContext();
  const value = get(values, name, null);
  const selectedOption = countries.find((o) => o.code === value) || null;

  const handleChange = (event: React.SyntheticEvent<Element, Event>, option: unknown) => {
    if (option === null) {
      setFieldValue(name, '');
    } else if ((option as Country).code) {
      setFieldValue(name, (option as Country).code);
    }
    if (onChange) {
      onChange();
    }
  };

  return (
    <>
      <PSelectAsync
        name={name}
        label={label}
        value={selectedOption}
        options={countries}
        disableClearable={true}
        getOptionLabel={(option: unknown) => (option as Country).name || ''}
        isOptionEqualToValue={(option, value) => {
          return value ? (option as Country).code === (value as Country).code : false;
        }}
        renderOption={(props, option) => {
          return (
            <li {...props} key={(option as Country).code}>
              {(option as Country).name}
            </li>
          );
        }}
        onChange={handleChange}
      />
    </>
  );
}
