import { useFormikContext } from 'formik';
import React, { useContext } from 'react';
import { User } from '../../../types';
import { PSelectAsync } from '../../pegasus/PSelectAsync';
import get from 'lodash/get';
import startCase from 'lodash/startCase';
import { getShortName } from '../../hooks/useGetDriverName';
import { Typography } from '@mui/material';
import DepotContext from '../../context/depot';

export default function DriverSelect({ name, label }: { name: string; label: string }) {
  const { setFieldValue, values } = useFormikContext();
  const { drivers, vehicles } = useContext(DepotContext);
  const value = get(values, name, null);
  const selectedOption = drivers.find((o) => o._id === value) || null;
  const selectedVehicle = vehicles.find(
    (v) => v.registrationNumber === selectedOption?.driverInformation?.currentRegistration
  );

  const handleChange = (event: React.SyntheticEvent<Element, Event>, option: unknown) => {
    if (option === null) {
      setFieldValue(name, '');
    } else if ((option as User)._id) {
      setFieldValue(name, (option as User)._id);
    }
  };

  return (
    <>
      <PSelectAsync
        name={name}
        label={label}
        value={selectedOption}
        options={drivers}
        getOptionLabel={(option: unknown) => getShortName(option as User) || ''}
        isOptionEqualToValue={(option, value) => {
          return value ? (option as User)._id === (value as User)._id : false;
        }}
        renderOption={(props, option) => {
          return (
            <li {...props} key={(option as User)._id}>
              {getShortName(option as User) || ''} ({startCase((option as User).type)})
            </li>
          );
        }}
        onChange={handleChange}
      />
      <Typography variant="textSmall" whiteSpace="pre-line" mb={2} display="block">
        {selectedVehicle?.description}
      </Typography>
    </>
  );
}
