import React from 'react';
import { Grid, Theme, Typography } from '@mui/material';
import { Consignment, TableField } from '../../../../types';
import get from 'lodash/get';
import { createStyles, makeStyles } from '@mui/styles';

const styles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.common.white,
      borderRadius: 6,
      overflow: 'hidden',
      marginBottom: 10,
      flexShrink: 0,
    },
    cell: {
      paddingTop: 18,
      paddingBottom: 20,
      paddingLeft: 18,
      paddingRight: 16,
    },
  })
);

const TableRow = ({
  consignment,
  tableFields,
  columnCount,
}: {
  consignment: Consignment;
  tableFields: TableField[];
  columnCount: number;
}) => {
  const classes = styles();
  return (
    <Grid container columns={columnCount} className={classes.container}>
      {tableFields.map((tableField, index) => {
        const fieldData = get(consignment, tableField.field, '');
        const rowClasses = [classes.cell];
        return (
          <Grid key={index} item xs={tableField.columns} className={rowClasses.join(' ')}>
            {tableField.renderField ? (
              tableField.renderField(consignment)
            ) : (
              <Typography variant="linkXSmall">{fieldData}</Typography>
            )}
          </Grid>
        );
      })}
    </Grid>
  );
};

export default TableRow;
