/* eslint-disable react/display-name */
import React, { useContext, useEffect, useState } from 'react';
import useAddressBookApiRoutes from '../../../hooks/api/useAddressBookApiRoutes';
import { AddressBook, AddressBookTableField } from '../../../../types';
import TableRow from '../../../components/Table/TableRow';
import TableHeader from '../../../components/Table/TableHeader';
import { PIcon } from '../../../pegasus/PIcon';
import { Colors } from '../../../pegasus/Colors';
import { IconButton, TablePagination } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import ModalContext from '../../../context/modal';
import UserContext from '../../../context/user';
import { DEFAULT_PAGE, ITEM_PER_PAGE } from '../../../constants/table';
import useGetUserRoutePermissions from '../../../hooks/useGetUserRoutePermissions';

const TableFields: AddressBookTableField[] = [
  {
    field: 'companyName',
    label: 'COMPANY',
    columns: 4,
  },
  { field: 'address', label: 'ADDRESS', columns: 5 },
  { field: 'additionalInfo', label: 'ADDITIONAL INFO', columns: 5 },
  { field: 'contactName', label: 'CONTACT', columns: 3 },
  { field: 'email', label: 'EMAIL', columns: 5 },
  { field: 'phone', label: 'PHONE #', columns: 3 },
  // {
  //   field: 'pickUp',
  //   label: 'PICK-UP',
  //   columns: 2,
  //   position: 'center',
  //   renderField: (addressBook: AddressBook) => <ViewIcon addressBook={addressBook} columName="pickUp" />,
  // },
  {
    field: 'milestones',
    label: 'MILESTONES',
    columns: 2,
    position: 'center',
    renderField: (addressBook: AddressBook) => <ViewIcon addressBook={addressBook} columName="milestones" />,
  },
  // {
  //   field: 'tracking',
  //   label: 'TRACKING',
  //   columns: 2,
  //   position: 'center',
  //   renderField: (addressBook: AddressBook) => <ViewIcon addressBook={addressBook} columName="tracking" />,
  // },
  {
    field: 'labels',
    label: 'LABELS',
    columns: 2,
    position: 'center',
    renderField: (addressBook: AddressBook) => <ViewIcon addressBook={addressBook} columName="labels" />,
  },
  // {
  //   field: 'consignment',
  //   label: 'CONSIGNMENT NOTE',
  //   columns: 3,
  //   position: 'center',
  //   renderField: (addressBook: AddressBook) => <ViewIcon addressBook={addressBook} columName="consignment" />,
  // },
  {
    field: 'actions',
    label: '',
    columns: 2,
    position: 'right',
    renderField: (addressBook: AddressBook) => <ViewButton addressBook={addressBook} />,
  },
];
const totalColumns = TableFields.reduce((accum, field) => accum + field.columns, 0);

const styles = makeStyles(() =>
  createStyles({
    paginationRoot: {
      color: Colors.primary.main,
      '& .MuiTablePagination-displayedRows': {
        color: Colors.primary.main,
        fontWeight: 600,
        fontSize: '13px',
        letterSpacing: '0.25px',
        lineHeight: '18px',
      },
    },
  })
);

const ViewButton = ({ addressBook }: { addressBook: AddressBook }) => {
  const { openDrawer, setAddressBook } = useContext(ModalContext);
  const { canEditAddressBook } = useGetUserRoutePermissions();
  const handleClick = () => {
    if (setAddressBook) {
      setAddressBook(addressBook);
    }
    openDrawer('edit-address-book');
  };
  return (
    <IconButton size="small" onClick={handleClick} disabled={!canEditAddressBook}>
      <PIcon
        name="edit"
        size={24}
        sx={{ color: canEditAddressBook ? Colors.primary.dark : Colors.grey[400], display: 'block' }}
      />
    </IconButton>
  );
};

const ViewIcon = ({ addressBook, columName }: { addressBook: AddressBook; columName: keyof AddressBook }) => {
  if (addressBook[columName]) return <PIcon name="check" sx={{ color: Colors.primary.main, display: 'block' }} />;
  else return <PIcon name="unCheck" sx={{ color: Colors.error.main, display: 'block' }} />;
};

type Props = {
  filter?: {
    searchText?: string;
  };
};

const AddressBookTable = ({ filter }: Props) => {
  const classes = styles();
  const { list } = useAddressBookApiRoutes();
  const [addressBooks, setAddressBooks] = useState<AddressBook[]>([]);
  const { isDrawerOpen } = useContext(ModalContext);
  const { user } = useContext(UserContext);
  const itemsPerPage = ITEM_PER_PAGE;
  const [totalItem, setTotalItem] = useState<number>(0);
  const [page, setPage] = useState<number>(DEFAULT_PAGE);
  const [currentSearchText, setCurrentSearchText] = useState<string>('');

  useEffect(() => {
    list({
      countryCode: user?.countryCode,
      page: page,
      itemsPerPage: itemsPerPage,
      searchText: currentSearchText,
    }).then((response) => {
      setAddressBooks(response.addressBooks);
      setTotalItem(response.total);
    });
  }, [list, user, page, currentSearchText, itemsPerPage]);

  useEffect(() => {
    if (filter && filter.searchText != undefined && filter.searchText !== currentSearchText) {
      setCurrentSearchText(filter.searchText);
      setPage(0);
    }
  }, [filter, currentSearchText]);

  useEffect(() => {
    if (!isDrawerOpen('edit-address-book')) {
      list({
        countryCode: user?.countryCode,
        page: page,
        itemsPerPage: itemsPerPage,
      }).then((response) => {
        setAddressBooks(response.addressBooks);
        setTotalItem(response.total);
      });
    }
  }, [list, user?.countryCode, page, isDrawerOpen, itemsPerPage]);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);
  };

  return (
    <>
      <TablePagination
        component="div"
        count={totalItem}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={itemsPerPage}
        rowsPerPageOptions={[]}
        classes={{ root: classes.paginationRoot }}
      />
      <TableHeader tableFields={TableFields} columnCount={totalColumns} />
      {addressBooks.map((addressBook) => {
        return (
          <TableRow key={addressBook._id} rowData={addressBook} tableFields={TableFields} columnCount={totalColumns} />
        );
      })}
    </>
  );
};

export default AddressBookTable;
