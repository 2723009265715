import React, { useRef } from 'react';
import { Box, Grid, Radio, RadioGroup, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { Colors } from '../../../pegasus/Colors';
import { useFormikContext } from 'formik';
import { ConsignmentFormValues } from './ConsignmentForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginBottom: theme.spacing(3),
    },
  })
);

const CheckInBooleanFields = () => {
  const classes = useStyles();
  const booleanFields = useRef([
    { label: 'Known Shipper', fieldName: 'checkInSheet.knownShipper', defaultValue: false },
    { label: 'Sufficient Packaging', fieldName: 'checkInSheet.sufficientPackaging', defaultValue: false },
    { label: 'ISPM Pallet', fieldName: 'checkInSheet.ipsmPallet', defaultValue: false },
    { label: 'Statement of Content (Leave blank if unsure)', fieldName: 'checkInSheet.statementOfContent' },
    {
      label: 'Was this shipment received in Tamper Evident Form',
      fieldName: 'checkInSheet.tamperEvidentForm',
      defaultValue: false,
    },
  ]).current;

  return (
    <Box className={classes.container}>
      <Grid container spacing={0.5}>
        <Grid item xs={7}></Grid>
        <Grid display="flex" justifyContent="center" item xs={1}>
          <Typography variant="textXSmall" color={Colors.greyscale.label}>
            YES
          </Typography>
        </Grid>
        <Grid display="flex" justifyContent="center" item xs={1}>
          <Typography variant="textXSmall" color={Colors.greyscale.label}>
            NO
          </Typography>
        </Grid>
      </Grid>
      {booleanFields.map((booleanField) => (
        <BooleanRow
          key={booleanField.fieldName}
          label={booleanField.label}
          fieldName={booleanField.fieldName}
          defaultValue={booleanField.defaultValue}
        />
      ))}
    </Box>
  );
};

const BooleanRow = ({
  label,
  fieldName,
  defaultValue,
}: {
  label: string;
  fieldName: string;
  defaultValue?: boolean;
}) => {
  const { setFieldValue } = useFormikContext<ConsignmentFormValues>();

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    setFieldValue(fieldName, value === 'true' ? true : false);
  };
  return (
    <RadioGroup defaultValue={defaultValue} name={fieldName} onChange={handleRadioChange}>
      <Grid container spacing={0.5} mb={2}>
        <Grid item xs={7}>
          <Typography variant="textMedium" color={Colors.greyscale.body}>
            {label}
          </Typography>
        </Grid>
        <Grid display="flex" justifyContent="center" item xs={1}>
          <Radio value={true} />
        </Grid>
        <Grid display="flex" justifyContent="center" item xs={1}>
          <Radio value={false} />
        </Grid>
      </Grid>
    </RadioGroup>
  );
};
export default CheckInBooleanFields;
