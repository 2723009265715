import React from 'react';
import { Grid, Typography } from '@mui/material';
import { PTextFieldMemo, RequiredFormikTextFields } from '../../../pegasus/PTextField';
import AddressSelect from '../../../components/form/AddressSelect';
import { PhoneNumberInput } from '../../../pegasus/PPhoneNumberInput';
import { ConsignmentFormValues } from './ConsignmentForm';
import { get, isEqual, pick } from 'lodash';
import { PDateTimePicker } from '../../../pegasus/PDateTimePicker';
import PTagsInput from '../../../pegasus/PTagsInput';

const ReceiverSection = ({
  values,
  ...formikProps
}: RequiredFormikTextFields & { values: Partial<ConsignmentFormValues> }) => {
  return (
    <>
      <Typography variant="displayXSmallBold" color="inherit" mb={3}>
        Receiver
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <PTextFieldMemo
            value={get(values, 'receiver.companyName')}
            name="receiver.companyName"
            fullWidth
            label="Company Name"
            {...formikProps}
          />
        </Grid>
        <Grid item xs={8}>
          <AddressSelect addressBookFieldName="receiver" name="receiver.address" fullWidth label="Address" />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PTextFieldMemo
            value={get(values, 'receiver.additionalInfo')}
            name="receiver.additionalInfo"
            fullWidth
            label="Additional Address Information"
            {...formikProps}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <PTextFieldMemo
            value={get(values, 'receiver.contactName')}
            name="receiver.contactName"
            fullWidth
            label="Contact Name"
            {...formikProps}
          />
        </Grid>
        <Grid item xs={4}>
          <PhoneNumberInput name="receiver.phone" fullWidth label="Phone Number" />
        </Grid>
        <Grid item xs={4}>
          <PTextFieldMemo
            value={get(values, 'receiver.email')}
            name="receiver.email"
            fullWidth
            label="Email"
            type="email"
            {...formikProps}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <PTagsInput  value={get(values, 'receiver.contactEmails')} name="eceiver.contactEmails" label="Additional Email" {...formikProps}/>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <PDateTimePicker name="preferredDeliveryTime" fullWidth label="Preferred Delivery Time" />
        </Grid>
        <Grid item xs={8}>
          <PTextFieldMemo
            value={get(values, 'deliveryInstructions')}
            name="deliveryInstructions"
            fullWidth
            label="Delivery Instructions"
            {...formikProps}
          />
        </Grid>
      </Grid>
    </>
  );
};

const formFields = [
  'receiver.companyName',
  'receiver.address',
  'receiver.additionalInfo',
  'receiver.contactName',
  'receiver.phone',
  'receiver.email',
  'receiver.contactEmails',
  'preferredDeliveryTime',
  'deliveryInstructions',
];
export default React.memo(ReceiverSection, (prevProps, nextProps) => {
  return isEqual(pick(prevProps.values, formFields), pick(nextProps.values, formFields));
});
