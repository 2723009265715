import React, { useEffect, useState } from 'react';
import { Consignment, User } from '../../../../types';
import { createStyles, makeStyles } from '@mui/styles';
import { Box, Theme, Typography, BoxProps } from '@mui/material';
import { Colors } from '../../../pegasus/Colors';
import useUserApiRoutes from '../../../hooks/api/useUserApiRoutes';

const styles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2),
      borderRadius: theme.spacing(1.5),
      backgroundColor: Colors.primary.contrastText,
      border: `2px solid ${Colors.primary.background}`,
    },
    image: {
      width: '100%',
    },
  })
);

function CheckInSignature({ consignment, ...orderBoxProps }: { consignment: Consignment } & BoxProps) {
  const [receivedByUser, setReceivedByUser] = useState<User>();
  const { getUser } = useUserApiRoutes();
  const classes = styles();
  useEffect(() => {
    let active = true;
    if (consignment.checkInSheet?.recievedBy) {
      getUser(consignment.checkInSheet?.recievedBy).then((user) => {
        if (active) {
          setReceivedByUser(user);
        }
      });
    }

    return () => {
      active = false;
    };
  }, [consignment.checkInSheet?.recievedBy, getUser]);

  return consignment.checkInSheet?.recievedBySignature?.signedUrl ? (
    <Box className={classes.container} {...orderBoxProps}>
      <Typography variant="textSmall">
        Signed by: {receivedByUser?.firstName} {receivedByUser?.lastName}
      </Typography>
      <img
        className={classes.image}
        src={consignment.checkInSheet.recievedBySignature.signedUrl}
        alt={consignment.checkInSheet.recievedBySignature.filename}
      />
      <Typography variant="textSmall">{consignment.checkInSheet.comments}</Typography>
    </Box>
  ) : null;
}

export default CheckInSignature;
