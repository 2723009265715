import React from 'react';
import { Marker, InfoBox } from '@react-google-maps/api';
import { Box, Divider, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { Colors } from '../../../../pegasus/Colors';
import { renderToStaticMarkup } from 'react-dom/server';
import { getDriverInitials } from '../../../../hooks/useGetDriverName';
import { Consignment, User } from '../../../../../types';
import { InfoBoxContentsWrapper, InfoWindowWidth } from './InfoBoxContentsWrapper';
import useFilterDriverConsignments from '../../../../hooks/useFilterDriverConsignments';
import useGetContextColor from '../../../../hooks/useGetContextColor';

export const SvgCircleSize = 37;
const SvgCircleMarker = ({ color, active }: { color: string; active: boolean }) => {
  const activeCircleColor = active ? Colors.primary.dark : Colors.greyscale.coolGrey;
  return (
    <svg
      width={SvgCircleSize}
      height={SvgCircleSize}
      viewBox={`0 0 ${SvgCircleSize} ${SvgCircleSize}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        // eslint-disable-next-line max-len
        d="M0 18C0 8.05887 8.05887 0 18 0V0C27.9411 0 36 8.05887 36 18V18C36 27.9411 27.9411 36 18 36V36C8.05887 36 0 27.9411 0 18V18Z"
        fill={color}
      />
      <circle cx="31" cy="31" r="5.5" fill={activeCircleColor} stroke="white" />
    </svg>
  );
};

const renderDriverMarkerUrl = (color: string, active = true) => {
  const markerSvgString = encodeURIComponent(renderToStaticMarkup(<SvgCircleMarker color={color} active={active} />));
  return `data:image/svg+xml;charset=utf-8,${markerSvgString}`;
};

const center = {
  lat: -36.979984387467205,
  lng: 174.77844557333128,
};

const styles = makeStyles(() =>
  createStyles({
    mapMarkerText: {
      fontWeight: 600,
      fontSize: 13,
    },
  })
);

export const DriverMarker = ({
  driver,
  onClick,
  onCloseInfoBox,
  isActive,
  index,
}: {
  driver: User;
  onClick: (driverId: string) => void;
  isActive: boolean;
  onCloseInfoBox: () => void;
  index: number;
}) => {
  const classes = styles();
  const handleMarkerClick = () => {
    onClick(driver._id);
  };
  const thisDriverConsignments = useFilterDriverConsignments(driver);
  const position =
    driver.driverInformation?.lastKnownPosition?.lat && driver.driverInformation?.lastKnownPosition.lng
      ? driver.driverInformation?.lastKnownPosition
      : { lat: center.lat, lng: center.lng + 0.005 * index };
  return (
    <Marker
      position={position}
      icon={{
        // anchor: new google.maps.Point(16, 16),
        url: renderDriverMarkerUrl(driver.driverInformation?.color || '', driver.driverInformation?.isActive),
      }}
      label={{ text: getDriverInitials(driver), color: 'white', className: classes.mapMarkerText }}
      onClick={handleMarkerClick}
    >
      {isActive ? (
        <InfoBox
          onCloseClick={onCloseInfoBox}
          options={{
            alignBottom: true,
            maxWidth: InfoWindowWidth,
            pixelOffset: new google.maps.Size(-0.5 * InfoWindowWidth, -1 * SvgCircleSize),
          }}
        >
          <InfoBoxContentsWrapper>
            {thisDriverConsignments.length === 0 ? (
              <Typography variant="textXSmall" color={Colors.greyscale.offBlack}>
                No Consignments
              </Typography>
            ) : (
              thisDriverConsignments.map((c, index) => (
                <DriverConsignmentRow key={c._id} consignment={c} index={index} />
              ))
            )}
          </InfoBoxContentsWrapper>
        </InfoBox>
      ) : null}
    </Marker>
  );
};

const DriverConsignmentRow = ({ index, consignment }: { index: number; consignment: Consignment }) => {
  const contextColor = useGetContextColor(consignment);

  return (
    <Box>
      {index > 0 ? <Divider sx={{ borderColor: 'white', my: 0.5 }} /> : null}
      <Typography mb={0} display="block" variant="textXSmall" color={Colors.greyscale.offBlack}>
        {consignment.orderType.toUpperCase()}
      </Typography>
      <Typography variant="textXSmall" color={contextColor}>
        #{consignment.consignmentId}
      </Typography>
    </Box>
  );
};
