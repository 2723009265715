import React, { useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import UserContext from '../context/user';
import Login from '../pages/Login';
import PasswordReset from '../pages/PasswordReset';
import SelectCountry from '../pages/SelectCountry';
import Accounts from '../pages/Admin/Accounts';
import OpsDashboard from '../pages/Operations/OpsDashboard';
import DispatchDashboard from '../pages/Dispatch/DispatchDashboard';
import OurDrivers from '../pages/Dispatch/OurDrivers';
import { DepotProvider } from '../context/depot';
import AddressBook from '../pages/Operations/address-book/AddressBook';
import Privacy from '../pages/Privacy';
import useGetUserRoutePermissions from '../hooks/useGetUserRoutePermissions';

const AppRouter = () => {
  const { isLoggedIn, user } = useContext(UserContext);
  const { canSeeOperationsDashboard } = useGetUserRoutePermissions();
  const defaultCountryCode = user?.countryCodeSignIn || user?.countryCode || 'FJ';
  return (
    <Routes>
      <Route path="/login/:countryCode" element={<Login />} />,
      <Route path="/reset/:token" element={<PasswordReset />} />,
      <Route path="/privacy-policy" element={<Privacy />} />,
      {isLoggedIn
        ? [
            <Route
              key="logged-in-root"
              path="/*"
              element={
                <Routes>
                  {[
                    user?.type !== 'driver'
                      ? [
                          <Route key="accounts" path="/admin/accounts/:countryCode" element={<Accounts />} />,
                          <Route
                            key="addressBook"
                            path="/operations/:countryCode/address-book"
                            element={<AddressBook />}
                          />,
                          <Route
                            key="dispatch-root"
                            path="/dispatch/:countryCode/:depotId/*"
                            element={
                              <DepotProvider>
                                <Routes>
                                  <Route
                                    key="redirect-dispatch-root"
                                    path="/"
                                    element={<Navigate replace to="dashboard" />}
                                  />
                                  ,
                                  <Route key="dispatchDashboard" path="/dashboard" element={<DispatchDashboard />} />
                                  ,
                                  <Route key="drivers" path="/drivers" element={<OurDrivers />} />
                                </Routes>
                              </DepotProvider>
                            }
                          />,
                        ]
                      : null,
                    user?.type === 'operations' ? (
                      <Route
                        key="redirect-root"
                        path="/"
                        element={<Navigate replace to={`/operations/${user.countryCode}/dashboard`} />}
                      />
                    ) : null,
                    user?.type === 'dispatch' ? (
                      <Route
                        key="redirect-dispatch-root"
                        path="/"
                        element={
                          <Navigate
                            replace
                            to={`dispatch/${user.countryCode}/${
                              user.depotIds && user.depotIds.length > 0 ? user.depotIds[0] : ''
                            }`}
                          />
                        }
                      />
                    ) : null,
                    canSeeOperationsDashboard
                      ? [
                          <Route
                            key="redirect-root"
                            path="/"
                            element={<Navigate replace to={`/admin/accounts/${defaultCountryCode}`} />}
                          />,
                          <Route
                            key="opsDashboard"
                            path="/operations/:countryCode/dashboard"
                            element={<OpsDashboard />}
                          />,
                        ]
                      : null,
                  ]}
                </Routes>
              }
            />,
          ]
        : [<Route key="select-country" path="/" element={<SelectCountry />} />]}
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
};

export default AppRouter;
