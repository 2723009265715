import { Consignment } from '../../types';

export default function useGetConsignmentAddressObject() {
  return (consignment: Consignment) => {
    return consignment.orderType === 'drop-off'
      ? consignment.receiver // drop-off use receiver
      : consignment.orderType === 'pick-up' // pick-up use sender
      ? consignment.sender
      : consignment.orderType === 'transfer' &&
        consignment.assignedToDriverLeg2 === undefined &&
        consignment.status !== 'atDepot'
      ? consignment.sender // transfer and no DriverLeg2 use sender
      : consignment.orderType === 'transfer' &&
        !(consignment.assignedToDriverLeg2 === undefined && consignment.status !== 'atDepot')
      ? consignment.receiver // transfer and DriverLeg2 use receiver
      : undefined;
  };
}
