import React from 'react';
import { Grid, Theme, Typography } from '@mui/material';
import { Consignment, TableField } from '../../../../types';
import get from 'lodash/get';
import { format } from 'date-fns';
import { createStyles, makeStyles } from '@mui/styles';
import useGetContextColor from '../../../hooks/useGetContextColor';

const styles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.common.white,
      borderRadius: 6,
      overflow: 'hidden',
      marginBottom: 10,
    },
    firstColumn: {
      borderLeftStyle: 'solid',
      borderLeftWidth: 6,
    },
    cell: {
      display: 'flex',
      alignItems: 'center',
      paddingTop: 18,
      paddingBottom: 20,
      paddingLeft: 18,
      paddingRight: 16,
    },
  })
);

const TableRow = ({
  consignment,
  tableFields,
  columnCount,
}: {
  consignment: Consignment;
  tableFields: TableField[];
  columnCount: number;
}) => {
  const classes = styles();
  const contextColor = useGetContextColor(consignment);
  return (
    <>
      <Grid container columns={columnCount} className={classes.container}>
        {tableFields.map((tableField, index) => {
          let fieldData = get(consignment, tableField.field, '');
          if (tableField.formatDate === true && fieldData) {
            fieldData = format(new Date(fieldData), 'd MMM, h:mm aa');
          }

          const rowClasses = [classes.cell];
          if (index === 0) {
            rowClasses.push(classes.firstColumn);
          }
          return (
            <Grid
              key={index}
              item
              xs={tableField.columns}
              className={rowClasses.join(' ')}
              sx={{ borderLeftColor: contextColor }}
            >
              {tableField.renderField ? (
                tableField.renderField(consignment)
              ) : (
                <Typography variant="linkXSmall">{fieldData}</Typography>
              )}
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default TableRow;
